import { useEffect } from "react";
import Spline from '@splinetool/react-spline';
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useState } from "react";

// files
import "../style/Home.css";
import Footer from "./other components/Footer";
import Client from "./other components/Client";
import MenuBar from "./other components/Menu";
import Cart from "./other components/Cart";
import SearchBox from "./other components/Searchbox";
import NavBar from "./other components/Navbar";

// images
import Logo from "./images/logo.png";
import HumanIcon from "./images/download.png"

// product JSON file
import featuredProducts from "./products/featuredProducts";

const Home = () => {

    // Add to cart, wishlist

    const tokenn = Cookies.get("Shanti Dental Distributors");
    const navigate = useNavigate();

    const addToCart = async (e, productName, productCat, inStock, actualPrice, discountPrice, image, brandName) => {

        e.preventDefault();

        try {
            const res = await fetch("https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/post/cart", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity: 1, tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                console.log(data.message);
                console.log("added to cart")
                window.location.reload();
            } else {
                console.log("Login Failed");
                if (data.error === "User not found") {
                    console.log(data.error)
                    navigate(`/login`);
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    // add to wishList
    const addToWishList = async (e, productName, productCat, inStock, actualPrice, discountPrice, image, brandName) => {

        e.preventDefault();
        // console.log(productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity, tokenn)

        try {
            const res = await fetch("https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/post/wishlist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity: 1, tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                console.log(data.message);
                console.log("added to wishlist")
                window.location.reload();
            } else {
                console.log("error");
                if (data.error === "User not found") {
                    console.log(data.error)
                    navigate(`/login`);
                } else if (data.error === "Already added the to Wishlist !") {
                    alert(data.error)
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    const [wishListItems, setwishListItems] = useState([{}]);

    const gettingDataWishlist = async (e) => {
        // console.log(token)
        try {
            const res = await fetch(`https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/getwishlist`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                setwishListItems(data);
                console.log(JSON.stringify(wishListItems))
                // console.log(total)
            } else {
                // navigate("/usernotfound");
            }

        } catch (error) {
            console.log(error);
        }

    }

    const RenderingMenu = () => {
        if (window.innerWidth <= 750) {
            return (<>
                <div className="productsNav" style={{ marginTop: "10px" }}>
                    <NavBar />
                </div>
            </>)
        } else {
            return (<>
                <NavBar />
            </>)
        }
    }

    useEffect(() => {
        gettingDataWishlist();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="section1Mainmost">
                {/* top line */}
                <div className="aboutMain">
                    {/* top line */}
                    <div className="topBarMain">

                        <div className="topBar">
                            <div className="topBarOne">
                                <p style={{ fontFamily: "'Jost', sans-serif" }} >FREE SHIPPING FOR ALL ORDERS ABOVE ₹ 1,999</p>
                            </div>
                            <div className="topBarTwo">
                                <i className="fa-brands fa-facebook-f"></i>
                                <a style={{ textDecoration: "none", color: "black" }} href="https://www.instagram.com/shantidentalhyd?igsh=MWdlYXVpdHIzMGE4Yg==">
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                                <a style={{ textDecoration: "none", color: "black" }} href="https://g.co/kgs/UdorgXw">
                                    <i className="fa-brands fa-google"></i>
                                </a>
                                <a style={{ textDecoration: "none", color: "black" }} href="https://wa.me/919704726590">
                                    <i className="fa-brands fa-whatsapp"></i>
                                </a>
                                {/* <i className="fa-brands fa-telegram"></i> */}
                            </div>
                        </div>

                    </div>

                    {/* Navbar */}
                    <NavBar />



                    <div className="secOneMain">

                        <div className="secOne">

                            <div className="secOneSub">
                                <Spline scene="https://prod.spline.design/edOQd4jGflEBQvw2/scene.splinecode" />
                            </div>

                            <div className="secOneSub2">
                                <h2>One Stop Shop  <br /> for Dentists !</h2>
                                {/* <p className="subTxt">DENTAL INSTRUMENTS & ESSENTIALS</p> */}
                                <div className="btnDiv">
                                    <NavLink to="/allProducts"><button>SHOP NOW</button></NavLink>
                                    <a href="#trackOrder"> <button className="aboutBtn" >TRACK ORDER</button></a>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>

                <div className="attributesSectionMain">
                    <div className="attributesSection">
                        <div className="attributeSub">
                            <i className="fa-solid fa-truck"></i>
                            <span>Speedy Delivery</span>
                        </div>
                        <div className="attributeSub">
                            <i className="fa-solid fa-tools"></i>
                            <span>Assured Quality</span>
                        </div>
                        <div className="attributeSub">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <span>Trusted Brands</span>
                        </div>
                        <div className="attributeSub">
                            <i className="fa-solid fa-thumbs-up"></i>
                            <span>Genuine Products</span>
                        </div>
                    </div>
                </div>
                
            </div>

            {/* Quote section */}

            <div className="quoteMain">

                <div className="quoteSection">
                    <span className="q1">Discover Our Top Brands </span>
                    <span className="q2">Shop from Trusted Brands with Assured Quality !</span>
                </div>

                <Client />

            </div>



            {/* Section 2 : Featured Products */}

            <div className="section2Main">

                <div className="section2">
                    <h2>Featured Products</h2>

                    <div className="featuredProducts">
                        {featuredProducts.map((data) => {
                            return (

                                <NavLink to={`/shop/${(data.productName).replaceAll(" ", "-")}`} style={{ textDecoration: "none", color: "black" }} className="productsDiv">
                                    {/* <div className="bgImgProducts" style={{backgroundImage:`url("https://eshantidental.com/wp-content/uploads/2021/05/Aspirating-Syringe-SYRA1.8ml-1-600x447.jpg")`}}> */}
                                    <div className="bgImgProducts" style={{ backgroundImage: `url(${data.image})` }}>
                                        <button className="discountProducts">
                                            -{Math.round(((data.actualPrice - data.discountPrice) * 100) / data.actualPrice)}%
                                        </button>
                                        <div className="toolsProducts">
                                            <i className="fa-solid fa-cart-shopping" onClick={(e) => addToCart(e, data.productName, data.productCat, data.inStock, data.actualPrice, data.discountPrice, data.image, data.brandName, data.email)}></i>
                                            {/* <i className="fa-solid fa-magnifying-glass"></i> */}
                                            <i className="fa-regular fa-eye"></i>
                                            {/* {
                                                wishListItems.filter(()=>{
                                                    return (" ")
                                                })
                                            } */}
                                            <i className="fa-regular fa-heart" onClick={(e) => addToWishList(e, data.productName, data.productCat, data.inStock, data.actualPrice, data.discountPrice, data.image, data.brandName, data.email)}></i>
                                        </div>
                                    </div>
                                    <div className="detailsProducts">
                                        <span style={{ fontWeight: "600", padding: "5px 0px" }}>{data.productName}</span>
                                        <span>{data.brandName}</span>
                                        <span className="pricingProducts">
                                            <strike style={{ fontSize: "13px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {data.actualPrice}</strike>
                                            <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {data.discountPrice}</span>
                                        </span>
                                    </div>

                                </NavLink>

                            )
                        })}

                    </div>
                    <a className="moreBtn" href="/allProducts">SEE MORE</a>


                </div>

            </div>

            {/* Track your order */}

            <div className="orderTrackMain" id="trackOrder">
                <div className="orderTrackDiv">
                    <h2 className="reviewHeading">Track your Order</h2>
                    <span className="tracktxt">To track your order please enter your Order ID in the box below and press the "Track" button.
                        <br />This was given to you on your receipt and in the confirmation email you should have received.</span>
                    <div className="orderInputs">
                        <input type="text" placeholder="Enter Billing Email" />
                        <input type="text" placeholder="Enter Order ID" />
                    </div>
                    <button className="trackBtn">TRACK</button>
                </div>
            </div>

            {/* Section 3 */}

            <div className="testiMonialsMain">
                <div className="testimonialsDiv">

                    <h2 className="reviewHeading">Our Reviews</h2>

                    <div className="reviewsDiv">

                        <div className="reviewSub">
                            <div className="reviewStars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>

                            <p className="reviewTxt">I have been purchasing dental products from Shanti Dentals Hyderabad, satisfied with their quality and service. The products are always delivered on time, and the prices are very competitive. Highly recommended!</p>

                            <div className="reviewPerson">
                                <div className="personDetails">
                                    <span className="reviewName">Rajesh Kumar</span>
                                    <span className="reviewPosition">Andheri, Mumbai</span>
                                </div>
                                <img className="humanIcon" src={HumanIcon} alt="" />
                            </div>

                        </div>

                        <div className="reviewSub">
                            <div className="reviewStars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>

                            <p className="reviewTxt">As a dentist, I need reliable suppliers for my clinic. Shanti Dentals Hyderabad has been my go-to source for all dental supplies. Their product range is extensive, and the customer support is excellent. I trust their products completely.</p>

                            <div className="reviewPerson">
                                <div className="personDetails">
                                    <span className="reviewName">Dr. Arjun Verma</span>
                                    <span className="reviewPosition">Jubliee Hills, Hyderabad</span>
                                </div>
                                <img className="humanIcon" src={HumanIcon} alt="" />
                            </div>

                        </div>

                        <div className="reviewSub">
                            <div className="reviewStars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>

                            <p className="reviewTxt">Shanti Dentals Hyderabad offers the best dental products in the market. The quality is top-notch, and the prices are reasonable. I have never faced any issues with their products or services. They are my preferred supplier</p>

                            <div className="reviewPerson">
                                <div className="personDetails">
                                    <span className="reviewName">Priya Sharma</span>
                                    <span className="reviewPosition">Secunderabad</span>
                                </div>
                                <img className="humanIcon" src={HumanIcon} alt="" />
                            </div>

                        </div>

                        <div className="reviewSub">
                            <div className="reviewStars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>

                            <p className="reviewTxt">I recently ordered some dental equipment from Shanti Dentals Hyderabad, and I am very impressed with the quality. The products are durable and meet all my expectations. The team is also very responsive and helpful. Great experience overall!</p>

                            <div className="reviewPerson">
                                <div className="personDetails">
                                    <span className="reviewName">Neha Patel </span>
                                    <span className="reviewPosition">Gachibowli</span>
                                </div>
                                <img className="humanIcon" src={HumanIcon} alt="" />
                            </div>

                        </div>
                        <div className="reviewSub">
                            <div className="reviewStars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>

                            <p className="reviewTxt">Shanti Dentals Hyderabad provides excellent dental products at competitive prices. Their customer service is prompt and efficient. I have been a loyal customer for years and will continue to rely on them for my clinic's needs.</p>

                            <div className="reviewPerson">
                                <div className="personDetails">
                                    <span className="reviewName">Dr. Suman Reddy</span>
                                    <span className="reviewPosition">Jubilee Hills</span>
                                </div>
                                <img className="humanIcon" src={HumanIcon} alt="" />
                            </div>

                        </div>
                        <div className="reviewSub">
                            <div className="reviewStars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>

                            <p className="reviewTxt">Shopping from Shanti Dentals Hyderabad, I couldn't be happier with their service. The products are always of high quality, and the team ensures timely delivery. Their commitment to customer satisfaction is truly commendable.</p>

                            <div className="reviewPerson">
                                <div className="personDetails">
                                    <span className="reviewName">Dr. Anjali Mehta</span>
                                    <span className="reviewPosition">Bangalore</span>
                                </div>
                                <img className="humanIcon" src={HumanIcon} alt="" />
                            </div>

                        </div>

                    </div>

                    <button className="moreRev" onClick={() => window.location = "https://g.co/kgs/UdorgXw"}>See More Reviews</button>

                </div>
            </div>

            {/* Footer */}
            <Footer />

        </>
    )
}

export default Home;
// Set-ExecutionPolicy -Scope Process -ExecutionPolicy Bypass