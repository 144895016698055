import React from "react";
import Sidebar from "react-sidebar";
import "../../style/MenuBar.css";
import MenuComp from "./MenuComp";

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false
        };
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    render() {

        return (
            <Sidebar
                sidebar={
                    <MenuComp />
                }
                open={this.state.sidebarOpen}
                onSetOpen={this.onSetSidebarOpen}
                styles={{ sidebar: { background: "white" } }}
                style={{
                   
                }}
            >
                <i className="fa fa-bars menuNav" onClick={() => this.onSetSidebarOpen(true)}></i>
            </Sidebar>
        );
    }
}

// import React from "react";
// import Sidebar from "react-sidebar";
// import "../../style/MenuBar.css";
// import MenuComp from "./MenuComp";
// import { slide as MenuBar } from 'react-burger-menu';

// const Menu = () => {
//     return (
//         <>
//             <MenuBar width={350} left customBurgerIcon={<i className='fa fa-bars menuNav'></i>} >
//                 <MenuComp />
//             </MenuBar>
//         </>
//     )
// }

export default Menu;