import { useNavigate, NavLink } from "react-router-dom";
// import "../CSS/Client.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./style/Main.css";
import "./style/Common.css";
import Logo from "../components/images/logonobg.png";

function Forms() {

    const navigate = useNavigate();

    const [formDataHome, setformDataHome] = useState([]);

    const tokenn = Cookies.get("Shanti Dental Distributors");

    const gettingDataHome = async (e) => {
        try {
            const res = await fetch(`https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/homecontact`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                setformDataHome(data.reverse())
            } else {
                // navigate("/usernotfound");
            }

        } catch (error) {
            console.log(error);
            navigate("/usernotfound");
        }

    }

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = formDataHome.slice(firstIndex, lastIndex);
    const npage = Math.ceil(formDataHome.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    const prePage = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage === numbers.length) {
            setCurrentPage(numbers.length)
        } else {
            setCurrentPage(currentPage + 1)
        }
    }

    const [showProfiles, setShowProfiles] = useState(false)

    useEffect(() => {
        gettingDataHome();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {/* <div className="dashboardMain">
                <img onClick={() => navigate("/en")} className="dashLogo" src={Logo} alt="" />
                <NavLink className="logoutDash" to="/logout" >Logout</NavLink>
            </div> */}

            <div className="dashMain">

                <div className="dashMainSlide">

                    {/* img */}
                    <NavLink to="/dashboard/home" style={{ color: "white", textDecoration: "none" }} className="mainImg">
                        <img src={Logo} alt="logo" />
                        <span>Shanti Dental <br /> Distributors</span>
                    </NavLink>

                    <div className="mainServices">
                        <NavLink className="mainServicesspan" to="/dashboard/profile" ><i className="fa-solid fa-user"></i><span>Profile</span></NavLink>
                        <div style={{ display: "flex", flexDirection: "column" }} onMouseEnter={() => setShowProfiles(true)} onMouseLeave={() => setShowProfiles(false)}>
                            <span className="mainServicesspan"  ><i className="fa-solid fa-box-archive"></i><span>Products</span></span>
                            {
                                showProfiles ? <div style={{ display: "flex", flexDirection: "column" }}  >
                                    <NavLink className="mainServicesspan" to="/dashboard/allproducts">All Products</NavLink>
                                    <NavLink className="mainServicesspan" to="/dashboard/newproducts">New Products</NavLink>
                                    <NavLink className="mainServicesspan" to="/dashboard/productscat">Categories</NavLink>
                                </div> : ""
                            }
                        </div>
                        <NavLink className="mainServicesspan" to="/dashboard/orders"><i className="fa-solid fa-cart-shopping"></i><span>Orders</span></NavLink>
                        <NavLink className="mainServicesspan" to="/dashboard/customers"><i className="fa-solid fa-people-group"></i><span>Customers</span></NavLink>
                        <NavLink style={{ marginLeft: "3px" }} className="mainServicesspan selectedService" to="/dashboard/forms"><i className="fa-solid fa-book"></i><span style={{ marginLeft: "3px" }}  >Forms</span></NavLink>
                    </div>

                </div>

                <div className="dashMainCntxt" style={{ background: "#ececec" }}>
                <h2 style={{ borderLeft: "7px solid #2271b1", paddingLeft: "20px", position: "relative", left: "35px" }} >FORMS</h2>
                    <center>

                        <table className="dashtable" style={{ marginTop: "70px" }}>

                            <thead>
                                <tr>
                                    <th className="dashth" id="thSelect" ><input type="checkbox" name="" id="" /> </th>
                                    <th className="dashth" id="thName" >Full Name</th>
                                    <th className="dashth" id="thEmail" >Email</th>
                                    <th className="dashth" id="thPhone" >Phone</th>
                                    <th className="dashth" id="thMsg" >Message</th>
                                </tr>
                            </thead>

                            <tbody >

                                {
                                    records.map((currVal) => {
                                        return (
                                            <tr key={currVal._id} >
                                                <td className="dashtd" id="thSelect" ><input type="checkbox" name="" id="" /></td>
                                                <td className="dashtd" id="thNameTD" style={{ boxSizing: "border-box" }} >{currVal.name}</td>
                                                <td className="dashtd" id="thEmailTD" style={{ boxSizing: "border-box" }} >{currVal.email}</td>
                                                <td className="dashtd" id="thPhoneTD" style={{ boxSizing: "border-box" }} >{currVal.phone}</td>
                                                <td className="dashtd" id="thMsgTD" style={{ boxSizing: "border-box" }} onMouseEnter={() => navigator.clipboard.writeText(currVal.message)} >
                                                    <i style={{ cursor: "pointer" }} className="fa fa-eye" ></i>
                                                </td>
                                                <span className="msgDiv" id="msgDiv">
                                                    <span className="msgDivSub">
                                                        {currVal.message}
                                                    </span>
                                                </span>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>

                        </table>

                    </center>

                    <div className="paginationDiv" >
                        <a href="#" onClick={prePage}><i className="fa fa-less-than"></i></a>
                        <span>{currentPage} of {numbers.length}</span>
                        <a href="#" onClick={nextPage} ><i className="fa fa-greater-than"></i></a>
                    </div>

                </div>

            </div>

        </>
    );
}

export default Forms;