import { useState } from "react";
import "../../style/MenuBar.css";
import { useNavigate } from "react-router-dom";

const MenuComp = ({ openBtn }) => {

    const navigate = useNavigate();

    const [menu, setMenu] = useState(true);
    const [styleForMenu, setstyleForMenu] = useState({ borderBottom: "2px solid rgb(60, 60, 193)", color: "black" });
    const [styleForCat, setstyleForCat] = useState({});

    const onClickMenu = () => {
        setstyleForMenu({ borderBottom: "2px solid rgb(60, 60, 193)", color: "black" });
        setstyleForCat({})
    }

    const onClickCat = () => {
        setstyleForMenu({});
        setstyleForCat({ borderBottom: "2px solid rgb(60, 60, 193)", color: "black" })
    }

    const MenuItems = () => {

        if (menu === true) {
            return (
                <div className="slideTwoBtn2">
                    <span onClick={() => navigate("/wishlist")} ><i className="fa-regular fa-heart"></i> WISHLIST</span>
                    <span onClick={() => navigate("/login")} ><i className="fa-regular fa-user"></i>LOGIN / REGISTER</span>
                    <span onClick={() => navigate("/cart")} ><i className="fa fa-shopping-cart"></i>CART</span>
                    <span onClick={() => navigate("/")}>HOME</span>
                    <span onClick={() => navigate("/allProducts")}>SHOP</span>
                    <span onClick={() => navigate("/about")}>ABOUT US</span>
                    <span onClick={() => navigate("/contact")}>CONTACT US</span>
                </div>
            )
        } else {
            return (
                <div className="slideTwoBtn2">
                    <span onClick={() => navigate("/category/PROSTHODONTICS")}> PROSTHODONTICS</span>
                    <span onClick={() => navigate("/category/ORTHODONTICS")}> ORTHODONTICS</span>
                    <span onClick={() => navigate("/category/ENDODONTICS")}> ENDODONTICS</span>
                    <span onClick={() => navigate("/category/MINOR EQUIPMENTS")}> MINOR EQUIPMENTS</span>
                    <span onClick={() => navigate("/category/PERIODONTICS")}> PERIODONTICS</span>
                    <span onClick={() => navigate("/category/GDC INSTRUMENTS")}> GDC INSTRUMENTS</span>
                    <span onClick={() => navigate("/category/RESTORATIVE DENTISTRY")}> RESTORATIVE DENTISTRY</span>
                    <span onClick={() => navigate("/category/PEDODONTICS")}> PEDODONTICS</span>
                    <span onClick={() => navigate("/category/DISINFECTION & STERILISATION")}> DISINFECTION & STERILISATION</span>
                    <span onClick={() => navigate("/category/ORAL SURGERY")}> ORAL SURGERY</span>
                    <span onClick={() => navigate("/category/RADIOGRAPHY")}> RADIOGRAPHY</span>
                </div>
            )
        }
    }

    const onClckMenu = () => {
        onClickMenu()
        setMenu(true)
    }

    const onClckCat = () => {
        onClickCat()
        setMenu(false)
    }

    return (<>

        <div className={openBtn ? "menuSlideMainShow" : "menuSlideMain"}>

            {/* 1 */}
            <div className="menuSlideOne">
                <input type="text" placeHolder="Search For Products" />
                <i className="fa fa-magnifying-glass" ></i>
            </div>

            {/* 2 */}
            <div className="menuSlideTwo">
                <div className="slideTwoBtn">
                    <span onClick={onClckMenu} style={styleForMenu}>MENU</span>
                    <span onClick={onClckCat} style={styleForCat}>CATEGOIRES</span>
                </div>
                <MenuItems />
            </div>

        </div>
    </>
    )
}

export default MenuComp;