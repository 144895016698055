import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

import Logo from "../images/logo.png";
import Cart from "./Cart";
import Footer from "./Footer";
import "../../style/Contact.css";
import SearchBox from "./Searchbox";
import NavBar from "./Navbar";

const Contact = () => {

    // Submitting contact form

    const [contactData, setContactDiv] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    });

    const setLoginVal = (e) => {
        const { name, value } = e.target;
        setContactDiv({ ...contactData, [name]: value });
    }

    const postLoginDetails = async (e) => {

        e.preventDefault();

        const { name, email, phone, message } = contactData;

        try {
            const res = await fetch("https://shantidentalbackend.vercel.app/contactpost", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name, email, phone, message
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                console.log("Contact Submitted");
                alert("Form Submitted")
                window.location.reload(false);
            } else {
                console.log("Login Failed");
                if (data.error === "Invalid credentials") {
                    console.log(data.error)
                } else if (data.error === "Enter all the details") {
                    console.log(data.error)
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <div className="contactMain">
                {/* top line */}
                <div className="topBarMain">

                    <div className="topBar">
                        <div className="topBarOne">
                            <p style={{ fontFamily: "'Jost', sans-serif" }} >FREE SHIPPING FOR ALL ORDERS ABOVE ₹ 1,999</p>
                        </div>
                        <div className="topBarTwo">
                            <i className="fa-brands fa-facebook-f"></i>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://www.instagram.com/shantidentalhyd?igsh=MWdlYXVpdHIzMGE4Yg==">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://g.co/kgs/UdorgXw">
                                <i className="fa-brands fa-google"></i>
                            </a>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://wa.me/919704726590">
                                <i className="fa-brands fa-whatsapp"></i>
                            </a>
                            {/* <i className="fa-brands fa-telegram"></i> */}
                        </div>
                    </div>

                </div>

                {/* Navbar */}
                <NavBar />
                
            </div>

            {/* contact us */}

            <div id="contact" className="partFour">

                <center><h1 className="contactHeading" >Contact Us</h1></center>

                <div className="contactUsDiv">

                    <div className="contactPartOneMain" >

                        {/* Contact details */}
                        <div className="contactPartOne">
                            {/* <h3>Get in touch</h3> */}

                            <div className="contactPartOneSub" >
                                <i className="fa-solid fa-phone"></i>
                                <div>
                                    <p style={{ fontWeight: "bold", fontSize: "20px", color: "black !important" }} className="contactP" >Phone:</p>
                                    <span>+91 9704726590</span>
                                    <span>+91 9247502150</span>
                                </div>
                            </div>


                            <div className="contactPartOneSub" >
                                <i className="fa-solid fa-envelope"></i>
                                <div>
                                    <p style={{ fontWeight: "bold", fontSize: "20px", color: "black !important" }} className="contactP" >Email:</p>
                                    <span>info@shantidentaldistributor.com</span>
                                </div>
                            </div>

                            <div className="contactPartOneSub" >
                                <i className="fa-solid fa-location-crosshairs"></i>
                                <div>
                                    <p style={{ fontWeight: "bold", fontSize: "20px", color: "black !important" }} className="contactP" >Location:</p>
                                    <span>G-21 Ground Floor, Agarwal Chambers,</span>
                                    <span>King Koti, Hyderabad</span>
                                </div>
                            </div>

                        </div>

                        {/* Contact form */}

                        <div className="contactForm">
                            <h4>Pass your Query to us,</h4>
                            <form className="inputsDiv">

                                <div className="inputsDivOne" >
                                    <div className="inputsDivOneSub" >
                                        <p>Full Name:</p>
                                        <div>
                                            <i className="fa-solid fa-user"></i>
                                            <input required autoComplete="off" type="text" name="name" value={contactData.name} onChange={setLoginVal} />
                                        </div>
                                    </div>

                                    <div className="inputsDivOneSub" >
                                        <p>Email:</p>
                                        <div>
                                            <i className="fa-solid fa-envelope"></i>
                                            <input required autoComplete="off" type="email" name="email" value={contactData.email} onChange={setLoginVal} />
                                        </div>
                                    </div>

                                    <div className="inputsDivOneSub" >
                                        <p>Phone:</p>
                                        <div>
                                            <i className="fa-solid fa-phone"></i>
                                            <input required autoComplete="off" type="number" name="phone" value={contactData.phone} onChange={setLoginVal} />
                                        </div>
                                    </div>

                                    <div className="inputsDivOneSub inputsDivOneSubMsg" >
                                        <p>Message:</p>
                                        <div>
                                            <textarea cols="30" rows="10" required autoComplete="off" type="text" name="message" value={contactData.message} onChange={setLoginVal} />
                                        </div>
                                    </div>

                                    <button onClick={postLoginDetails} className="submitContact" type="submit">Submit</button>

                                </div>

                                <div className="inputsDivTwo" >
                                    <p>Message:</p>
                                    <textarea required autoComplete="off" type="text" name="message" value={contactData.message} onChange={setLoginVal} ></textarea>
                                </div>

                            </form>


                        </div>


                    </div>


                    <div className="contactPartTwo">
                        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15229.453031845604!2d78.4796821!3d17.3943463!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9976da461047%3A0x908da70dc0609f90!2sShanti%20Dental%20Distributor!5e0!3m2!1sen!2sin!4v1717025721633!5m2!1sen!2sin" width="100%" style={{ border: "0", height: "60vh", borderRadius: "10px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                </div>

            </div>


            {/* footer */}
            <Footer />

        </>
    )
}

export default Contact;