import Logo from "../images/logo.png";
import "../../style/Checkout.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

import Footer from "./Footer";

const CheckOut = () => {

    const token = Cookies.get("Shanti Dental Distributors");

    // Setting details

    const [shippingDetails, setShippingDet] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        address1: "",
        address2: "",
        state: "",
        city: "",
        postalCode: "",
        drRegNo: ""
    })

    const setShippingVal = (e) => {
        const { name, value } = e.target;
        setShippingDet({ ...shippingDetails, [name]: value });
    }

    const [billingDetail, setbillingDetail] = useState({
        billingfirstName: "",
        billinglastName: "",
        billingemail: "",
        billingphone: "",
        billingcompany: "",
        billingaddress1: "",
        billingaddress2: "",
        billingstate: "",
        billingcity: "",
        billingpostalCode: "",
        billingdrRegNo: ""
    })

    const setBillingVal = (e) => {
        const { name, value } = e.target;
        setbillingDetail({ ...billingDetail, [name]: value });
    }

    const [stateForShipping, setStateForShipping] = useState("Andhra Pradesh")
    const [stateForBilling, setStateForBilling] = useState("Andhra Pradesh")
    const [paymentMode, setpaymentMethod] = useState("")


    // checkout bakchodi

    const navigate = useNavigate();

    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState()

    const gettingData = async (e) => {
        // console.log(token)
        try {
            const res = await fetch(`https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/cartData`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                setCartItems(data)
                console.log(data)

                let totalPrice = 0
                data.forEach(item => {
                    setTotal(totalPrice += item.discountPrice * item.quantity)
                });

                // console.log(total)
            } else {
                // navigate("/usernotfound");
            }

        } catch (error) {
            console.log(error);
        }

    }

    const gettingCartVal = () => {
        let totalPrice = 0
        cartItems.forEach(item => {
            setTotal(totalPrice += item.discountPrice * item.quantity)
        });

        // console.log(total)
    }


    const updatingCheckoutVal = async (e, price) => {

        e.preventDefault();

        // console.log(token, e, productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity)
        try {
            const res = await fetch("https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/set/cartVal", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token, total: price
                })
            });

            if (res.status === 200) {
                console.log("checked out!")
            } else {
                console.log("Couldn't update")
            }

        } catch (error) {
            console.log(error)
        }
    }

    const deleteCartItem = async (e, id, price) => {
        e.preventDefault();

        try {
            const res = await fetch("https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/deleteCartItem", {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id, token
                })
            });


            if (res.status === 200) {
                console.log("Deleted!");
                updatingCheckoutVal(e, total - price);
                gettingCartVal();
                window.location.reload(false);
            } else {
                console.log("Couldn't delete")
            }
        } catch (error) {
            console.log(error)
        }

    }

    const [error, setErr] = useState("")

    // posting order
    const postingShipping = async (e) => {

        if (paymentMode === "cod") {

            const { firstName, lastName, email, phone, company, address1, address2, city, postalCode, drRegNo } = shippingDetails
            const { billingfirstName, billinglastName, billingemail, billingphone, billingcompany, billingaddress1, billingaddress2, billingcity, billingpostalCode, billingdrRegNo } = billingDetail

            try {
                const res = await fetch(`https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/postOrder`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        firstName, lastName, email, phone, company, address1, address2, state: stateForShipping, city, postalCode, drRegNo, billingfirstName, billinglastName, billingemail, billingphone, billingcompany, billingaddress1, billingaddress2, billingstate: stateForBilling, billingcity, billingpostalCode, billingdrRegNo, tokenn: token, paymentMode, cartProducts: cartItems
                    })
                });
  
                const data = await res.json();

                if (res.status === 200) {
                    // console.log(data)
                    navigate("/orderSuccess")
                } else if (data.error === "User not found") {
                    navigate("/usernotfound");
                } else if (data.error === "Enter all the details") {
                    setErr("Enter all the details")
                }

            } catch (error) {
                console.log(error);
            }

        } else if (paymentMode === "online") {

            const { firstName, lastName, email, phone, company, address1, address2, city, postalCode, drRegNo } = shippingDetails
            const { billingfirstName, billinglastName, billingemail, billingphone, billingcompany, billingaddress1, billingaddress2, billingcity, billingpostalCode, billingdrRegNo } = billingDetail

            if (!firstName || !lastName || !email || !phone || !address1 || !address2 || !city || !postalCode ) {
                setErr("Enter all the details")
            } else {
                try {
                    const res = await fetch(`https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/post/onlineorder`, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            firstName, lastName, email, phone, company, address1, address2, state: stateForShipping, city, postalCode, drRegNo, billingfirstName, billinglastName, billingemail, billingphone, billingcompany, billingaddress1, billingaddress2, billingstate: stateForBilling, billingcity, billingpostalCode, billingdrRegNo, tokenn: token, paymentMode, cartProducts: cartItems
                        })
                    });

                    const order = await res.json();
                    console.log((order.amount) * 100);

                    var options = {
                        "key": "rzp_test_Cj9NUSD5XYmfYq", // Enter the Key ID generated from the Dashboard
                        "amount": total, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                        "currency": "INR",
                        "name": "SHANTI DENTAL DISTRIBUTOR", //your business name
                        "description": "Test Transaction",
                        "image": "https://example.com/your_logo",
                        "order_id": order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                        "handler": async function (response) {
                            const body = {
                                ...response, firstName, lastName, email, phone, company, address1, address2, state: stateForShipping, city, postalCode, drRegNo, billingfirstName, billinglastName, billingemail, billingphone, billingcompany, billingaddress1, billingaddress2, billingstate: stateForBilling, billingcity, billingpostalCode, billingdrRegNo, tokenn: token, paymentMode, cartProducts: cartItems
                            };

                            const validateRes = await fetch(
                                "https://shantidentalbackend.vercel.app/order/validate",
                                {
                                    method: "POST",
                                    body: JSON.stringify(body),
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                }
                            );
                            const jsonRes = await validateRes.json();
                            console.log(jsonRes);
                            if (jsonRes.msg === "success") {
                                navigate("/orderSuccess")
                            } else {

                            }
                        },
                        "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                            "name": `${firstName} ${lastName}`, //your customer's name
                            "email": email,
                            "contact": phone  //Provide the customer's phone number for better conversion rates 
                        },
                        "notes": {
                            "address": "Razorpay Corporate Office"
                        },
                        "theme": {
                            "color": "#3399cc"
                        }
                    };
                    var rzp1 = new window.Razorpay(options);
                    rzp1.on('payment.failed', function (response) {
                        alert(response.error.code);
                        alert(response.error.description);
                        alert(response.error.source);
                        alert(response.error.step);
                        alert(response.error.reason);
                        alert(response.error.metadata.order_id);
                        alert(response.error.metadata.payment_id);
                    });
                    rzp1.open();
                    e.preventDefault();


                    // if (res.status === 200) {
                    //     console.log(data)
                    // } else {
                    //     // navigate("/usernotfound");
                    // }

                } catch (error) {
                    console.log(error);
                }
            }

        }



    }

    useEffect(() => {
        gettingData();
        gettingCartVal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // Payment toggle down
    const [idForCod, setIdForCod] = useState("toggleTextHidden");
    const [idForOnline, setIdForOnline] = useState("toggleTextHidden");

    const clkCod = () => {
        setIdForCod("toggleText")
        setIdForOnline("toggleTextHidden")
    }

    const clkOnline = () => {
        setIdForOnline("toggleText")
        setIdForCod("toggleTextHidden")
    }

    // Billing address toggle down
    const [differentAddress, setdifferentAddress] = useState("toggleTextHidden");

    const clkAddress = () => {
        setdifferentAddress("toggleText")
    }

    const clkSameAddress = () => {
        setdifferentAddress("toggleTextHidden")
    }

    return (<>

        <div className="checkoutMain">

            {/* section 1 */}

            <div className="checkoutSec1">

                <NavLink className="checkOutLogoDiv" to="/" style={{ textDecoration: "none" }}>
                    <img src={Logo} alt="" />
                    <h3>
                        SHANTI DENTAL DISTRIBUTOR
                    </h3>
                </NavLink>

                <div className="checkoutContactDiv">
                    <span className="numberSpan">
                        <i className="fa fa-phone"></i>
                        9704726590, 9247502150
                    </span>
                    <span>
                        <i className="fa fa-envelope"></i>
                        info@shantidentaldistributor.com
                    </span>
                </div>

            </div>

            {/* Section 2 */}

            <div className="checkoutSec2">
                {/* {stateForBilling}
                {stateForShipping} */}

                <div className="checkoutSec2Sub1Main">
                    <div className="checkoutSec2Sub1" style={{ paddingBottom: "10px" }}>
                        <span>
                            <h3>SHIPPING DETAILS</h3>
                        </span>

                        <div className="shippingInputs">

                            <div>
                                <input name="firstName" value={shippingDetails.firstName} onChange={setShippingVal} type="text" placeholder="First Name" />
                                <input name="lastName" value={shippingDetails.lastName} onChange={setShippingVal} type="text" placeholder="Last Name" />
                            </div>

                            <div>
                                <input name="email" value={shippingDetails.email} onChange={setShippingVal} type="text" placeholder="Email" />
                                <input name="phone" value={shippingDetails.phone} onChange={setShippingVal} type="text" placeholder="Phone Number" />
                            </div>

                            <input name="company" value={shippingDetails.company} onChange={setShippingVal} type="text" placeholder="Company Name (optional)" />
                            <input name="address1" value={shippingDetails.address1} onChange={setShippingVal} type="text" placeholder="Address Line - 1" />
                            <input name="address2" value={shippingDetails.address2} onChange={setShippingVal} type="text" placeholder="Address Line - 2" />

                            <div className="checkoutAddressDiv" >

                                <select name="state" onChange={(e) => setStateForShipping(e.target.value)}>
                                    <option value="default" selected> Andhra Pradesh</option>
                                    <option> Arunachal Pradesh</option>
                                    <option> Assam</option>
                                    <option> Bihar</option>
                                    <option> Chhattisgarh</option>
                                    <option> Goa</option>
                                    <option> Gujarat</option>
                                    <option> Haryana</option>
                                    <option> Himachal Pradesh</option>
                                    <option> Jharkhand</option>
                                    <option> Karnataka</option>
                                    <option> Kerala</option>
                                    <option> Maharashtra</option>
                                    <option> Madhya Pradesh</option>
                                    <option> Manipur</option>
                                    <option> Meghalaya</option>
                                    <option> Mizoram</option>
                                    <option> Nagaland</option>
                                    <option> Odisha</option>
                                    <option> Punjab</option>
                                    <option> Rajasthan</option>
                                    <option> Sikkim</option>
                                    <option> Tamil Nadu</option>
                                    <option> Tripura</option>
                                    <option> Telangana</option>
                                    <option> Uttar Pradesh</option>
                                    <option> Uttarakhand</option>
                                    <option> West Bengal</option>
                                    <option> Andaman & Nicobar (UT)</option>
                                    <option> Chandigarh (UT)</option>
                                    <option> Dadra & Nagar Haveli and Daman & Diu (UT)</option>
                                    <option> Delhi [National Capital Territory (NCT)]</option>
                                    <option> Jammu & Kashmir (UT)</option>
                                    <option> Ladakh (UT)</option>
                                    <option> Lakshadweep (UT)</option>
                                    <option> Puducherry (UT)</option>
                                </select>

                                <input name="city" value={shippingDetails.city} onChange={setShippingVal} type="text" placeholder="City / Town" />
                                <input name="postalCode" value={shippingDetails.postalCode} onChange={setShippingVal} type="text" placeholder="Postcode / ZIP" />

                            </div>

                            <input name="drRegNo" value={shippingDetails.drRegNo} onChange={setShippingVal} type="text" placeholder="Dr. Registration Number" />

                        </div>
                    </div>

                    {/* Payment */}

                    <div className="checkoutSec2Sub1Main" style={{ width: "100%", marginTop: "30px", border: "1px solid  rgb(233, 233, 233)" }}>
                        <div className="checkoutSec2Sub1">
                            <span>
                                <h3>Payment</h3>
                            </span>
                            {/* <h6>All transactions are secure and encrypted</h6> */}

                            <div className="toggleDownDiv" onClick={() => setpaymentMethod("cod")}>
                                <label onClick={clkCod} id="toggleLabel" className="togglebtn"><input type="radio" name="payment" className="toggleRadioInput" />Cash On Delivery</label>
                                <div id={idForCod} className="toggleDown">
                                    <p>Pay with cash upon delivery.</p>
                                </div>
                            </div>

                            <div className="toggleDownDiv" onClick={() => setpaymentMethod("online")}>
                                <label onClick={clkOnline} id="toggleLabel" className="togglebtn"><input type="radio" name="payment" className="toggleRadioInput" />Credit Card/Debit Card/NetBanking</label>
                                <div id={idForOnline} className="toggleDown">
                                    <p>Pay securely by Credit or Debit card or Internet Banking through Razorpay.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Billing address */}

                    <div className="checkoutSec2Sub1Main" style={{ width: "100%", marginTop: "30px", border: "1px solid  rgb(233, 233, 233)" }}>
                        <div className="checkoutSec2Sub1">
                            <span>
                                <h3>Billing Address</h3>
                            </span>
                            {/* <h6>All transactions are secure and encrypted</h6> */}

                            <div className="toggleDownDiv">
                                <label onClick={clkSameAddress} id="toggleLabel" className="togglebtn"><input type="radio" name="address" className="toggleRadioInput" />Same as shipping address</label>
                                {/* <div className="toggleDown">
                                    <p>Pay with cash upon delivery.</p>
                                </div> */}
                            </div>

                            <div className="toggleDownDiv">
                                <label onClick={clkAddress} id="toggleLabel" className="togglebtn"><input type="radio" name="address" className="toggleRadioInput" />Use a different billing address</label>
                                <div id={differentAddress} className="toggleDown">
                                    <div className="shippingInputs">

                                        <div>
                                            <input style={{ border: "2px solid" }} name="billingfirstName" value={billingDetail.billingfirstName} onChange={setBillingVal} type="text" placeholder="First Name" />
                                            <input style={{ border: "2px solid" }} name="billinglastName" value={billingDetail.billinglastName} onChange={setBillingVal} type="text" placeholder="Last Name" />
                                        </div>

                                        <div>
                                            <input style={{ border: "2px solid" }} name="billingemail" value={billingDetail.billingemail} onChange={setBillingVal} type="text" placeholder="Email" />
                                            <input style={{ border: "2px solid" }} name="billingphone" value={billingDetail.billingphone} onChange={setBillingVal} type="text" placeholder="Phone Number" />
                                        </div>

                                        <input style={{ border: "2px solid" }} name="billingcompany" value={billingDetail.billingcompany} onChange={setBillingVal} type="text" placeholder="Company Name (optional)" />
                                        <input style={{ border: "2px solid" }} name="billingaddress1" value={billingDetail.billingaddress1} onChange={setBillingVal} type="text" placeholder="Address Line - 1" />
                                        <input style={{ border: "2px solid" }} name="billingaddress2" value={billingDetail.billingaddress2} onChange={setBillingVal} type="text" placeholder="Address Line - 2" />

                                        <div className="checkoutAddressDiv" >

                                            <select name="billingstate" style={{ border: "2px solid black" }} onChange={(e) => setStateForBilling(e.target.value)}>
                                                <option value="DEFAULT" selected> Andhra Pradesh</option>
                                                <option> Arunachal Pradesh</option>
                                                <option> Assam</option>
                                                <option> Bihar</option>
                                                <option> Chhattisgarh</option>
                                                <option> Goa</option>
                                                <option> Gujarat</option>
                                                <option> Haryana</option>
                                                <option> Himachal Pradesh</option>
                                                <option> Jharkhand</option>
                                                <option> Karnataka</option>
                                                <option> Kerala</option>
                                                <option> Maharashtra</option>
                                                <option> Madhya Pradesh</option>
                                                <option> Manipur</option>
                                                <option> Meghalaya</option>
                                                <option> Mizoram</option>
                                                <option> Nagaland</option>
                                                <option> Odisha</option>
                                                <option> Punjab</option>
                                                <option> Rajasthan</option>
                                                <option> Sikkim</option>
                                                <option> Tamil Nadu</option>
                                                <option> Tripura</option>
                                                <option> Telangana</option>
                                                <option> Uttar Pradesh</option>
                                                <option> Uttarakhand</option>
                                                <option> West Bengal</option>
                                                <option> Andaman & Nicobar (UT)</option>
                                                <option> Chandigarh (UT)</option>
                                                <option> Dadra & Nagar Haveli and Daman & Diu (UT)</option>
                                                <option> Delhi [National Capital Territory (NCT)]</option>
                                                <option> Jammu & Kashmir (UT)</option>
                                                <option> Ladakh (UT)</option>
                                                <option> Lakshadweep (UT)</option>
                                                <option> Puducherry (UT)</option>
                                            </select>

                                            <input style={{ border: "2px solid" }} name="billingcity" value={billingDetail.billingcity} onChange={setBillingVal} type="text" placeholder="City / Town" />
                                            <input style={{ border: "2px solid" }} name="billingpostalCode" value={billingDetail.billingpostalCode} onChange={setBillingVal} type="text" placeholder="Postcode / ZIP" />

                                        </div>

                                        <input style={{ border: "2px solid" }} name="billingdrRegNo" value={billingDetail.billingdrRegNo} onChange={setBillingVal} type="text" placeholder="Dr. Registration Number" />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Checkout btn */}
                    <p style={{ color: "red", position: "relative", top: "20px", left: "10px" }} >{error}</p>
                    <div className="checkoutSubmit">
                        <NavLink to="/"> {"<<"} return to home</NavLink>
                        <button onClick={postingShipping} >Complete Order</button>
                    </div>

                </div>

                <div className="checkoutSec2Sub2Main">

                    <div className="checkoutSec2Sub2">

                        <span className="checkoutSec2Sub1Span">
                            <h3>PRODUCTS</h3>
                        </span>

                        <div className="recentlyViewedDiv" style={{ marginTop: "20px", height: "auto", maxHeight: "472px", overflowY: "scroll", scrollbarWidth: "0px" }}>

                            {cartItems.map((data) => {
                                return (<>
                                    <NavLink to={`/shop/${(data.productName).replaceAll(" ", "-")}`} style={{ textDecoration: "none", color: "black", height: "130px", margin: "10px auto" }} className="recentlyViewedSub" >
                                        <div className="recenltViewImg" style={{ backgroundImage: `url(${data.image})` }} ></div>
                                        <div className="recentlyViewProd">
                                            <span>{data.productName}</span> <br />
                                            <span style={{ position: "relative", top: "" }}>
                                                <strike style={{ fontSize: "13px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {data.actualPrice}</strike>
                                                <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {data.discountPrice}</span>
                                            </span>
                                            <span style={{ fontSize: "10px", marginLeft: "5px" }} >QTY : {data.quantity}</span>
                                            <div className="toolsrecentView">
                                                <i onClick={(e) => deleteCartItem(e, data._id, data.discountPrice)} className="fa fa-trash trashIcon"></i>
                                            </div>
                                        </div>
                                    </NavLink>
                                </>)
                            })}

                        </div>
                    </div>

                    <div className="checkoutSec2Sub2" style={{ margin: "30px auto" }}>
                        <span className="checkoutSec2Sub1Span">
                            <h3>SUMMARY</h3>
                        </span>

                        <div className="summaryDiv">
                            <div><span>Subtotal: </span><span><i className="fa fa-indian-rupee-sign"></i>{total}</span></div>
                            <div><span>Shipping: </span><span><i className="fa fa-indian-rupee-sign"></i>40</span></div>
                            <hr style={{ width: "94%", height: "2px", background: "#333" }} />
                            <div className="summaryDivTotal"><span>Total: </span><span><i className="fa fa-indian-rupee-sign"></i>{total + 40}</span></div>
                        </div>

                    </div>

                    <div>

                    </div>

                </div>

            </div>


        </div>


        <Footer />
    </>)
}

export default CheckOut;