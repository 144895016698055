import "./style/Main.css";
import Logo from "../components/images/logonobg.png";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import Cookies from "js-cookie";

const Profile = () => {

    const [showProfiles, setShowProfiles] = useState(false);

    const [password, setPass] = useState("");
    const [error, setError] = useState("")
    const tokenn = Cookies.get("Shanti Dental Distributors");

    const changePass = async (e) => {

        e.preventDefault();

        try {
            const res = await fetch("https://shantidentalbackend.vercel.app/changepassAdmin", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    password, tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                alert("Password Changed");
                setError("")
                setPass("")
            } else {
                if (data.error === "user not registered") {
                    setError("Authentication failed")
                } else if (data.error === "Enter all the details") {
                    setError("Enter the password")
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className="dashMain">

                <div className="dashMainSlide">

                    {/* img */}
                    <NavLink to="/dashboard/home" style={{ color: "white", textDecoration: "none" }} className="mainImg">
                        <img src={Logo} alt="logo" />
                        <span>Shanti Dental <br /> Distributors</span>
                    </NavLink>

                    <div className="mainServices">
                        <NavLink className="mainServicesspan selectedService" to="/dashboard/profile" ><i className="fa-solid fa-user"></i><span>Profile</span></NavLink>
                        <div style={{ display: "flex", flexDirection: "column" }} onMouseEnter={() => setShowProfiles(true)} onMouseLeave={() => setShowProfiles(false)}>
                            <span className="mainServicesspan"  ><i className="fa-solid fa-box-archive"></i><span>Products</span></span>
                            {
                                showProfiles ? <div style={{ display: "flex", flexDirection: "column" }}  >
                                    <NavLink className="mainServicesspan" to="/dashboard/allproducts">All Products</NavLink>
                                    <NavLink className="mainServicesspan" to="/dashboard/newproducts">New Products</NavLink>
                                    <NavLink className="mainServicesspan" to="/dashboard/productscat">Categories</NavLink>
                                </div> : ""
                            }
                        </div>
                        <NavLink className="mainServicesspan" to="/dashboard/orders"><i className="fa-solid fa-cart-shopping"></i><span>Orders</span></NavLink>
                        <NavLink className="mainServicesspan" to="/dashboard/customers"><i className="fa-solid fa-people-group"></i><span>Customers</span></NavLink>
                        <NavLink style={{ marginLeft: "3px" }} className="mainServicesspan" to="/dashboard/forms"><i className="fa-solid fa-book"></i><span style={{ marginLeft: "3px" }}  >Forms</span></NavLink>
                    </div>

                </div>

                <div className="dashMainCntxt">
                    <h2 style={{ borderLeft: "7px solid #2271b1", paddingLeft: "20px", position: "relative", left: "35px" }} >PROFILE</h2>

                    <div className="profileInfoDiv">
                        <input type="text" placeholder="Name: Shanti Dental Distributor" disabled />
                        <input type="text" placeholder="Email: admin@theshantidental.com" disabled />
                        <input placeholder="Change Password" type="password" name="password" value={password} onChange={(e) => setPass(e.target.value)} />
                        <center>
                            <p style={{ color: "red" }} >{error}</p>
                        </center>
                        <button onClick={changePass} >SAVE</button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Profile;