import { useNavigate, NavLink } from "react-router-dom";
// import "../CSS/Client.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./style/Main.css";
import "./style/Common.css";
import "./style/Products.css";
import Logo from "../components/images/logonobg.png";

const OrderSearch = () => {

    const navigate = useNavigate();

    const [formDataHome, setformDataHome] = useState([]);
    const [viewData, setviewData] = useState([{
        address1: "", address2: "", billingaddress1: "", billingaddress2: "", billingcity: "", billingcompany: "", billingdrRegNo: "", billingemail: "", billingfirstName: "", billinglastName: "", billingphone: "", billingpostalCode: "", billingstate: "", cartValue: "", city: "", date: "", drRegNo: "", email: "", firstName: "", lastName: "", paymentMode: "", phone: "", postalCode: "", state: "", userEmail: "", userName: "", userPhone: "", _id: "", cartItems: []
    }]);

    const tokenn = Cookies.get("Shanti Dental Distributors");

    const gettingDataHome = async (e) => {
        try {
            const res = await fetch(`https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/getOrderDetails`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                setformDataHome(data.reverse())
                console.log(data)
            } else {
                // navigate("/usernotfound");
            }

        } catch (error) {
            console.log(error);
            navigate("/usernotfound");
        }

    }

    // search effect
    const [query, setQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const handleSearch = (event) => {
        const value = event.target.value;
        setQuery(value);

        if (value.length > 0) {
            const results = formDataHome.filter(item => item.firstName.toLowerCase().includes(value.toLowerCase()));
            setFilteredData(results);
        } else {
            setFilteredData([]);
        }
    };

    // const handleRedirect = (path) => {
    //     history(path);
    // };

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 9;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = filteredData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(filteredData.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    const prePage = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage === numbers.length) {
            setCurrentPage(numbers.length)
        } else {
            setCurrentPage(currentPage + 1)
        }
    }

    useEffect(() => {
        gettingDataHome();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [showProfiles, setShowProfiles] = useState(false);
    const [styleForBox, setSTyleForBox] = useState("hidden")

    return (<>
        <div className="dashMain">

            {/* slide */}
            <div className="dashMainSlide">

                {/* img */}
                <div className="mainImg">
                    <img src={Logo} alt="logo" />
                    <span>Shanti Dental <br /> Distributors</span>
                </div>

                <div className="dashMainSlide">

                    {/* img */}
                    <NavLink to="/dashboard/home" style={{ color: "white", textDecoration: "none" }} className="mainImg">
                        <img src={Logo} alt="logo" />
                        <span>Shanti Dental <br /> Distributors</span>
                    </NavLink>

                    <div className="mainServices">
                        <NavLink className="mainServicesspan" to="/dashboard/profile" ><i className="fa-solid fa-user"></i><span>Profile</span></NavLink>
                        <div style={{ display: "flex", flexDirection: "column" }} onMouseEnter={() => setShowProfiles(true)} onMouseLeave={() => setShowProfiles(false)}>
                            <span className="mainServicesspan"  ><i className="fa-solid fa-box-archive"></i><span>Products</span></span>
                            {
                                showProfiles ? <div style={{ display: "flex", flexDirection: "column" }}  >
                                    <NavLink className="mainServicesspan" to="/dashboard/allproducts">All Products</NavLink>
                                    <NavLink className="mainServicesspan" to="/dashboard/newproducts">New Products</NavLink>
                                    <NavLink className="mainServicesspan" to="/dashboard/productscat">Categories</NavLink>
                                </div> : ""
                            }
                        </div>
                        <NavLink className="mainServicesspan selectedService" to="/dashboard/orders"><i className="fa-solid fa-cart-shopping"></i><span>Orders</span></NavLink>
                        <NavLink className="mainServicesspan" to="/dashboard/customers"><i className="fa-solid fa-people-group"></i><span>Customers</span></NavLink>
                        <NavLink style={{ marginLeft: "3px" }} className="mainServicesspan" to="/dashboard/forms"><i className="fa-solid fa-book"></i><span style={{ marginLeft: "3px" }}  >Forms</span></NavLink>
                    </div>

                </div>

            </div>

            {/* Part next to start */}

            <div className="dashMainCntxt" style={{ background: "#ececec" }}>

                <h2 style={{ borderLeft: "7px solid #2271b1", paddingLeft: "20px", position: "relative", left: "35px" }} >ORDERS</h2>
                <center>

                    {/* SearchBox  */}
                    <div className="searchBoxMain" style={{ marginTop: "50px", marginBottom: "20px" }}>

                        <div className="searchBox">
                            <input type="text"
                                value={query}
                                onChange={handleSearch}
                                placeHolder="Search For Products"
                            />
                            <div className="searchiconBox">
                                <i className="fa fa-search searchIcon"></i>
                            </div>
                        </div>

                    </div>

                    {/* SearchBox  */}



                    <table className="dashtable">

                        <thead>
                            <tr>
                                <th className="dashth" ><input type="checkbox" name="" id="" /> </th>
                                <th className="dashth" >Order Details</th>
                                <th className="dashth" style={{ textAlign: "center" }} >Review</th>
                                <th className="dashth" >Date</th>
                                <th className="dashth" >Payment Type</th>
                                <th className="dashth" >Total</th>
                            </tr>
                        </thead>

                        <tbody >

                            {
                                records.map((currVal) => {
                                    return (
                                        <tr key={currVal._id} >

                                            <td className="dashtd" id="thSelectOrders" ><input type="checkbox" name="" id="" /></td>

                                            <td className="dashtd" id="thNameTDOrders" style={{ boxSizing: "border-box" }} >#{(currVal._id).slice(0, 8)} {currVal.firstName} {currVal.lastName}</td>

                                            <td className="dashtd" id="thMsgTDOrders" style={{ boxSizing: "border-box", textAlign: "center" }} >
                                                <i style={{ cursor: "pointer" }} onClick={() => { setviewData([{ address1: currVal.address1, address2: currVal.address2, billingaddress1: currVal.billingaddress1, billingaddress2: currVal.billingaddress2, billingcity: currVal.billingcity, billingcompany: currVal.billingcompany, billingdrRegNo: currVal.billingdrRegNo, billingemail: currVal.billingemail, billingfirstName: currVal.billingfirstName, billinglastName: currVal.billinglastName, billingphone: currVal.billingphone, billingpostalCode: currVal.billingpostalCode, billingstate: currVal.billingstate, cartValue: currVal.cartValue, city: currVal.city, date: currVal.date, drRegNo: currVal.drRegNo, email: currVal.email, firstName: currVal.firstName, lastName: currVal.lastName, paymentMode: currVal.paymentMode, phone: currVal.phone, postalCode: currVal.postalCode, state: currVal.state, userEmail: currVal.userEmail, userName: currVal.userName, userPhone: currVal.userPhone, _id: currVal._id, cartItems: currVal.cartProducts, orderId:currVal.orderId, paymentId:currVal.paymentId }]); setSTyleForBox("visible") }} className="fa fa-eye" ></i>
                                            </td>

                                            <td className="dashtd" id="thEmailTDOrders" style={{ boxSizing: "border-box" }} >{currVal.date}</td>

                                            <td className="dashtd" id="thPhoneTDOrders" style={{ boxSizing: "border-box", textTransform: "uppercase" }} >{currVal.paymentMode}</td>

                                            <td className="dashtd" id="thPhoneTDOrders" style={{ boxSizing: "border-box" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {currVal.cartValue}</td>

                                            <span className="msgDiv" id="msgDiv">
                                                <span className="msgDivSub">
                                                    {currVal.cartValue}
                                                </span>
                                            </span>

                                        </tr>
                                    )
                                })
                            }

                            <span className="msgDivOrder" style={{ visibility: styleForBox }}>
                                <span className="msgDivSubOrder">

                                    {/* setSTyleForBox */}
                                    <i onClick={() => setSTyleForBox("hidden")} style={{ cursor: "pointer" }} className="fa-solid fa-circle-xmark"></i>

                                    {
                                        viewData.map((data) => {
                                            return (
                                                <>
                                                    <div className="msgDivDetails">
                                                        <div className="shippingDetails">
                                                            <h3>Shipping details</h3>
                                                            <span>{data.firstName} {data.lastName}</span>
                                                            <span>{data.address1}</span>
                                                            <span>{data.city} {data.postalCode}</span>
                                                            <span>{data.state}</span>
                                                        </div>
                                                        <div className="shippingDetails">
                                                            <div className="billingDetails">
                                                                <h3>Billing details</h3>
                                                                <span>{data.billingfirstName} {data.billinglastName}</span>
                                                                <span>{data.billingaddress1}</span>
                                                                <span>{data.billingcity} {data.billingpostalCode}</span>
                                                                <span>{data.billingstate}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="msgDivDetails">
                                                        <div className="shippingDetails">
                                                            <h3>Email</h3>
                                                            <span>{data.email}</span>
                                                            <h3>Phone</h3>
                                                            <span>{data.phone}</span>
                                                        </div>
                                                        <div className="shippingDetails">
                                                            <div className="billingDetails">
                                                                <h3>Payment Method</h3>
                                                                <span>{data.paymentMode}</span>
                                                                <span>{data.orderId ? `Razorpay Order ID: ${data.orderId}` : ""}</span>
                                                                <span>{data.paymentId ? `Razorpay Payment ID: ${data.paymentId}` : ""}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="msgDivDetails">
                                                        <table style={{ width: "98%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th className="dashth" >Product</th>
                                                                    <th className="dashth" >Quantity</th>
                                                                    <th className="dashth" >Total</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody >

                                                                {
                                                                    data.cartItems.map((curVal) => {
                                                                        return (<>
                                                                            <tr>
                                                                                <th className="dashth" >{curVal.productName}</th>
                                                                                <th className="dashth" >{curVal.quantity}</th>
                                                                                <th className="dashth" >{curVal.discountPrice}</th>
                                                                            </tr>
                                                                        </>)
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </>
                                            )
                                        })
                                    }

                                </span>
                            </span>

                        </tbody>

                    </table>



                </center>

                <div className="paginationDiv" >
                    <a href="#" onClick={prePage}><i className="fa fa-less-than"></i></a>
                    <span>{currentPage} of {numbers.length}</span>
                    <a href="#" onClick={nextPage} ><i className="fa fa-greater-than"></i></a>
                </div>
            </div>

        </div>
    </>)
}

export default OrderSearch;