import "../../style/Client.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

//images
import GC from "../images/Featured Brands/gc.webp";
import GDC from "../images/Featured Brands/gdc.avif";
import MANI from "../images/Featured Brands/mani-2.webp";
import ORO from "../images/Featured Brands/oro.jpg";
import PrevestDenpro from "../images/Featured Brands/Prevest-Denpro.avif";
import PrimeDental from "../images/Featured Brands/prime-dental.avif";
import SafeEndo from "../images/Featured Brands/SafeEndo-logo-1.png";


const Client = () => {

    const partners = [
        { logo: GC },
        { logo: GDC },
        { logo: MANI },
        { logo: ORO },
        { logo: PrevestDenpro },
        { logo: PrimeDental },
        { logo: SafeEndo },
    ];

    return (<>

        <div className="sliderDivMainMost">

            {/* <h1><i className="fa-solid fa-less-than sliderBtns"></i></h1> */}
            <div className="partnerDIvMain">

                <Slider
                    dots={false}
                    infinite={true}
                    speed={2000}
                    slidesToShow={4}
                    slidesToScroll={1}
                    autoplay={true}
                    autoplaySpeed={4000}
                    cssEase={'linear'}
                    arrows={false}
                >
                    {partners.map((partner, index) => (
                        <div className="partnersDiv" key={index}>
                            <img src={partner.logo} alt={`Partner ${index + 1}`} />
                        </div>
                    ))}
                </Slider>

            </div>
            {/* <h1><i className="fa-solid fa-greater-than sliderBtns"></i></h1> */}
            
        </div>

    </>)
}

export default Client;