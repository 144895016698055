import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import Logo from "../images/logo.png";
import SearchBox from "./Searchbox";
import Cart from "./Cart";
import NavBar from "./Navbar";

const TrackOrder = () => {
    {/* Track your order */ }
    return (<>

        <div className="section1Mainmost" style={{minHeight:"50vh"}}>
            {/* top line */}
            <div className="aboutMain" style={{ minHeight: "180px" }}>

                <div className="topBarMain">

                    <div className="topBar">
                        <div className="topBarOne">
                            <p style={{ fontFamily: "'Jost', sans-serif" }} >FREE SHIPPING FOR ALL ORDERS ABOVE ₹ 1,999</p>
                        </div>
                        <div className="topBarTwo">
                            <i className="fa-brands fa-facebook-f"></i>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://www.instagram.com/shantidentalhyd?igsh=MWdlYXVpdHIzMGE4Yg==">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://g.co/kgs/UdorgXw">
                                <i className="fa-brands fa-google"></i>
                            </a>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://wa.me/919704726590">
                                <i className="fa-brands fa-whatsapp"></i>
                            </a>
                            {/* <i className="fa-brands fa-telegram"></i> */}
                        </div>
                    </div>

                </div>

                {/* Navbar */}
                <NavBar />

                <div className="orderTrackMain" id="trackOrder" style={{ marginTop: "30px" }}>
                    <div className="orderTrackDiv">
                        <h2 className="reviewHeading">Track your Order</h2>
                        <span className="tracktxt">To track your order please enter your Order ID in the box below and press the "Track" button.
                            <br />This was given to you on your receipt and in the confirmation email you should have received.</span>
                        <div className="orderInputs">
                            <input type="text" placeholder="Enter Billing Email" />
                            <input type="text" placeholder="Enter Order ID" />
                        </div>
                        <button className="trackBtn" style={{ background: "black" }}>TRACK</button>
                    </div>
                </div>

            </div>
        </div>



        <Footer />
    </>)
}

export default TrackOrder;