import { useNavigate, NavLink } from "react-router-dom";
// import "../CSS/Client.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./style/Main.css";
import "./style/Common.css";
import "./style/Products.css";
import Logo from "../components/images/logonobg.png";

const AllProDisplay = () => {

    const navigate = useNavigate();

    const [formDataHome, setformDataHome] = useState([]);

    const tokenn = Cookies.get("Shanti Dental Distributors");

    const gettingDataHome = async (e) => {
        try {
            const res = await fetch(`https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/products`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });

            const data = await res.json();

            if (res.status === 200) {
                setformDataHome(data.products)
            } else {
                // navigate("/usernotfound");
            }

        } catch (error) {
            console.log(error);
        }

    }

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 9;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = formDataHome.slice(firstIndex, lastIndex);
    const npage = Math.ceil(formDataHome.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    const prePage = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage === numbers.length) {
            setCurrentPage(numbers.length)
        } else {
            setCurrentPage(currentPage + 1)
        }
    }

    useEffect(() => {
        gettingDataHome();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [showProfiles, setShowProfiles] = useState(false)

    return (<>
        <div className="dashMain">

            {/* slide */}
            <div className="dashMainSlide">

                {/* img */}
                <NavLink to="/dashboard/home" style={{ color: "white", textDecoration: "none" }} className="mainImg">
                    <img src={Logo} alt="logo" />
                    <span>Shanti Dental <br /> Distributors</span>
                </NavLink>

                <div className="mainServices">
                    <NavLink className="mainServicesspan" to="/dashboard/profile" ><i className="fa-solid fa-user"></i><span>Profile</span></NavLink>
                    <div style={{ display: "flex", flexDirection: "column" }} onMouseEnter={() => setShowProfiles(true)} onMouseLeave={() => setShowProfiles(false)}>
                        <span className="mainServicesspan selectedService"  ><i className="fa-solid fa-box-archive"></i><span>Products</span></span>
                        {
                            showProfiles ? <div style={{ display: "flex", flexDirection: "column" }}  >
                                <NavLink className="mainServicesspan" to="/dashboard/allproducts">All Products</NavLink>
                                <NavLink className="mainServicesspan" to="/dashboard/newproducts">New Products</NavLink>
                                <NavLink className="mainServicesspan" to="/dashboard/productscat">Categories</NavLink>
                            </div> : ""
                        }
                    </div>
                    <NavLink className="mainServicesspan" to="/dashboard/orders"><i className="fa-solid fa-cart-shopping"></i><span>Orders</span></NavLink>
                    <NavLink className="mainServicesspan" to="/dashboard/customers"><i className="fa-solid fa-people-group"></i><span>Customers</span></NavLink>
                    <NavLink style={{ marginLeft: "3px" }} className="mainServicesspan" to="/dashboard/forms"><i className="fa-solid fa-book"></i><span style={{ marginLeft: "3px" }}  >Forms</span></NavLink>
                </div>

            </div>

            {/* Part next to start */}

            <div className="dashMainCntxt" style={{ background: "#ececec" }}>


                <center>

                    {/* SearchBox  */}
                    <div className="searchBoxMain" style={{ marginTop: "50px", marginBottom: "20px" }}>

                        <div className="searchBox">
                            <input type="text"
                                onClick={() => navigate("/dashboard/searchproduct")}
                                placeHolder="Search For Products"
                            />
                            <div className="searchiconBox">
                                <i className="fa fa-search searchIcon"></i>
                            </div>
                        </div>

                    </div>
                    {/* SearchBox  */}

                    <div className="featuredPageProductsDash">

                        {records.map((data) => {
                            return (

                                <div className="productsPageSubDiv">
                                    {/* <div className="bgImgProducts" style={{backgroundImage:`url("https://eshantidental.com/wp-content/uploads/2021/05/Aspirating-Syringe-SYRA1.8ml-1-600x447.jpg")`}}> */}
                                    <div className="bgImgProducts" style={{ backgroundImage: `url(${data.image})` }}>
                                        <div className="toolsProductsPage">
                                            <i className="fa-solid fa-pen" onClick={() => navigate(`/dashboard/edit/${(data.productName).replaceAll(" ", "-")}`)} ></i>
                                        </div>
                                    </div>
                                    <div className="detailsProducts">
                                        <span style={{ fontWeight: "600", padding: "5px 0px" }}>{data.productName}</span>
                                        <span>{data.brandName}</span>
                                        <span className="pricingProducts">
                                            <strike style={{ fontSize: "13px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {data.actualPrice}</strike>
                                            <span style={{ color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {data.discountPrice}</span>
                                        </span>
                                    </div>

                                </div>

                            )
                        })}

                    </div>

                </center>

                <div className="paginationDiv" >
                    <a href="#" onClick={prePage}><i className="fa fa-less-than"></i></a>
                    <span>{currentPage} of {numbers.length}</span>
                    <a href="#" onClick={nextPage} ><i className="fa fa-greater-than"></i></a>
                </div>
            </div>

        </div>

    </>)

}

export default AllProDisplay;