import "./style/Main.css";
import Logo from "../components/images/logonobg.png";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import bg from "./background.png"

const Profile = () => {

    const [showProfiles, setShowProfiles] = useState(false)

    return (
        <>
            <div className="dashMain">

                <div className="dashMainSlide">

                    {/* img */}
                    <NavLink to="/dashboard/home" style={{ color: "white", textDecoration: "none" }} className="mainImg">
                        <img src={Logo} alt="logo" />
                        <span>Shanti Dental <br /> Distributors</span>
                    </NavLink>

                    <div className="mainServices">
                        <NavLink className="mainServicesspan" to="/dashboard/profile" ><i className="fa-solid fa-user"></i><span>Profile</span></NavLink>
                        <div style={{ display: "flex", flexDirection: "column" }} onMouseEnter={() => setShowProfiles(true)} onMouseLeave={() => setShowProfiles(false)}>
                            <span className="mainServicesspan"  ><i className="fa-solid fa-box-archive"></i><span>Products</span></span>
                            {
                                showProfiles ? <div style={{ display: "flex", flexDirection: "column" }}  >
                                    <NavLink className="mainServicesspan" to="/dashboard/allproducts">All Products</NavLink>
                                    <NavLink className="mainServicesspan" to="/dashboard/newproducts">New Products</NavLink>
                                    <NavLink className="mainServicesspan" to="/dashboard/productscat">Categories</NavLink>
                                </div> : ""
                            }
                        </div>
                        <NavLink className="mainServicesspan" to="/dashboard/orders"><i className="fa-solid fa-cart-shopping"></i><span>Orders</span></NavLink>
                        <NavLink className="mainServicesspan" to="/dashboard/customers"><i className="fa-solid fa-people-group"></i><span>Customers</span></NavLink>
                        <NavLink style={{ marginLeft: "3px" }} className="mainServicesspan" to="/dashboard/forms"><i className="fa-solid fa-book"></i><span style={{ marginLeft: "3px" }}  >Forms</span></NavLink>
                    </div>

                </div>

                <div className="dashMainCntxt" style={{ paddingTop: "50px", backgroundImage: `url(${bg})`}}>
                    <h2 style={{ borderLeft: "7px solid #2271b1", paddingLeft: "20px", position: "relative", left: "35px" }} >HEY THERE USER!</h2>
                    <center>
                        <div style={{ width: "auto", maxWidth: "800px", background: "linear-gradient(180deg, rgba(211, 212, 226, 1) 0%, rgba(166, 229, 240, 1) 100%)", padding: "10px 30px 40px 30px", borderRadius: "12px", marginTop: "110px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}} >
                            <h2>WELCOME TO THE DASHBOARD OF</h2>
                            <h2>SHANTI DENTAL DISTRIBUTOR</h2>
                        </div>
                    </center>
                </div>
                
            </div>
        </>
    )
}

export default Profile;