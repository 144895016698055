import { slide as Menu } from 'react-burger-menu';
import "../../style/Cart.css";
import MenuComp from './MenuComp';

const Menu2 = () => {

    return (
        <Menu width={350} right customBurgerIcon={<i className='fa-solid fa-bars' style={{fontSize:"50px"}}></i>} >
            <MenuComp />
        </Menu>
    );
}

export default Menu2;