import "../../style/Cart.css";
import "../../style/MenuBar.css";
import MenuComp from './MenuComp';
import { useState } from "react";

const Menu3 = () => {

    const [showMenu, setShowMenu] = useState(false);

    return (

        <>
            <i className="fa fa-bars menuBurgerIcn" onClick={() => showMenu ? setShowMenu(false) : setShowMenu(true)}></i>

            {
                showMenu ? <i class="fa-solid fa-xmark menuCrossIcon" onClick={() => setShowMenu(false)}></i> : ""
            }

            <MenuComp openBtn={showMenu} />

            {
                showMenu ? <div className="transDiv" onClick={() => setShowMenu(false)}></div> : ""
            }

        </>

    );
}

export default Menu3;