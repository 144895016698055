import { NavLink } from "react-router-dom";
import "../../style/Footer.css";
import Logo from "../images/logonobg.png";
// import Logo2 from "../images/logowtagline-removebg-full.png";

const Footer = () => {

    return (
        <>
            <div className="footerMain">

                <div className="footerDiv">

                    {/* section 1 */}

                    <div className="footerSub1">

                        <div className="footerImgntagline">
                            <img className="footerLogo" src={Logo} alt="logo" />
                            <span>
                                Our motto at Shanti Dental Distributor is to facilitate a vast range of dental products across Indian and Foreign Companies.
                            </span>
                        </div>

                        <div className="footerContact">

                            <span>
                                <i className="fa fa-location-dot"></i> G-21 Ground Floor, Agarwal Chambers, King Koti, Hyderabad
                            </span>
                            <span>
                                <i className="fa fa-mobile-screen-button"></i> 9704726590, 9247502150
                            </span>
                            <span>
                                <i className="fa fa-envelope"></i>info@shantidentaldistributor.com
                            </span>

                        </div>

                    </div>

                    {/* Section 2 */}

                    <div className="footerSub2">
                        <div className="footerSub2Sub">

                            <div className="footerLinks">
                                <div>
                                    <p>Quick Links</p>
                                    <span><NavLink className="footerLinksNav" to="/" >Home</NavLink></span>
                                    <span><NavLink className="footerLinksNav" to="/allproducts" >Shop</NavLink></span>
                                    <span><NavLink className="footerLinksNav" to="/about" >About Us</NavLink></span>
                                </div>
                            </div>

                            <div className="footerLinks">
                                <div>
                                    <p>POLICY</p>
                                    <span><NavLink className="footerLinksNav" to="/privacy-policy"  >Privacy Policy </NavLink></span>
                                    <span><NavLink className="footerLinksNav" to="/termsnconditions"  >Terms & Conditions </NavLink></span>
                                    <span><NavLink className="footerLinksNav" to="/refundpolicy"  >Shipping, Refund, <br />Cancellation policy </NavLink></span>
                                </div>
                            </div>

                            <div className="footerLinks">
                                <div>
                                    <p>Support</p>
                                    <span><NavLink className="footerLinksNav" to="/contact"  >Contact us</NavLink></span>
                                    <span><a className="footerLinksNav" href="#trackOrder" onClick={()=>  window.location.href = "/trackOrder"} >Track Order</a></span>
                                </div>
                            </div>

                        </div>

                        <div className="footerSub2Sec">
                            <div className="socialLinks">
                                <p>Get in touch</p>
                                <a href="/"><i className="fa-brands fa-facebook-f"></i></a>

                                <a href="https://www.instagram.com/shantidentalhyd?igsh=MWdlYXVpdHIzMGE4Yg==">
                                    <i className="fa-brands fa-instagram"></i>
                                </a>

                                <a  href="https://g.co/kgs/UdorgXw">
                                    <i className="fa-brands fa-google"></i>
                                </a>

                                <a  href="https://wa.me/919704726590">
                                    <i className="fa-brands fa-whatsapp"></i>
                                </a>

                                {/* <i className="fa-brands fa-telegram"></i> */}

                                {/* <a href="/"><i className="fa-brands fa-telegram"></i></a> */}
                            </div>
                        </div>
                    </div>

                </div>

                <p className="copyRightTxt" ><i className="fa-regular fa-copyright"></i> Shanti Dental 2024. </p>
                {/* <p className="copyRightTxt" ><i className="fa-regular fa-copyright"></i> Shanti Dental 2024. All Rights Reserved</p>  */}

            </div>

        </>
    )

}

export default Footer;