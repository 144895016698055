import "../../style/Shop.css";
import { useNavigate, NavLink, useParams, useLocation } from "react-router-dom";
import Logo from "../images/logo.png";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

import Footer from "./Footer";
import Cart from "./Cart";
import SearchBox from "./Searchbox";
import NavBar from "./Navbar";

const Shop = () => {

    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);
    const [alreadyWishlist, setalreadyWishlist] = useState(false);

    const addQuantity = () => {
        setQuantity(quantity + 1)
    }

    const reduceQuantity = () => {
        setQuantity(quantity - 1)
    }

    // getting product data

    // const navigate = useNavigate();

    const location = useLocation();
    const pathname = location.pathname;
    const pathSegments = pathname.split('/');
    const productIdFromUrl = pathSegments[pathSegments.length - 1];

    const [productData, setproductData] = useState([]);
    // const [productReview, setproductReview] = useState([]);

    const tokenn = Cookies.get("Shanti Dental Distributors");

    const gettingData = async (e) => {
        // console.log((productIdFromUrl).replaceAll("-", " "))
        try {
            const res = await fetch(`https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/post/productname`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    tokenn, productName: (productIdFromUrl).replaceAll("-", " ")
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                setproductData(data.isProduct)
                // setproductReview(data.isProduct.review)
            } else {
                // navigate("/usernotfound");
            }

        } catch (error) {
            console.log(error);
            // navigate("/usernotfound");
        }

    }

    // add to cart
    const addToCart = async (e, productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity) => {

        e.preventDefault();
        console.log(productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity, tokenn)

        try {
            const res = await fetch("https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/post/cart", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity, tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                console.log(data.message);
                console.log("added to cart")
                window.location.reload();
            } else {
                console.log("Login Failed");
                if (data.error === "User not found") {
                    console.log(data.error)
                    navigate(`/login`);
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    // already added to wishlist
    const checkWishList = async (e) => {

        // console.log(productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity, tokenn)

        try {
            const res = await fetch("https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/alreadyadded/wishlist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    productName: (productIdFromUrl).replaceAll("-", " "), tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                setalreadyWishlist(false)
                console.log(data)
            } else if (res.status === 204) {
                setalreadyWishlist(true)
            } else {
                console.log(data)
            }

        } catch (error) {
            console.log(error)
        }
    }

    // add to wishList
    const addToWishList = async (e, productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity) => {

        e.preventDefault();
        // console.log(productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity, tokenn)

        try {
            const res = await fetch("https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/post/wishlist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    productName, productCat, inStock, actualPrice, discountPrice, image, brandName, quantity, tokenn
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                console.log(data.message);
                console.log("added to wishlist")
                window.location.reload();
            } else {
                console.log("error");
                if (data.error === "User not found") {
                    console.log(data.error)
                    navigate(`/login`);
                } else if (data.error === "Already added the to Wishlist !") {
                    alert(data.error)
                }
            }

        } catch (error) {
            console.log(error)
        }

    }


    // Scroll to the top of this page when mounted
    useEffect(() => {
        gettingData();
        checkWishList();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>

        <div className="shop">

            {/* Navbar */}
            <div >
                <NavBar />
            </div>
            {/* Navbar ends */}

            {/* product details */}

            <div className="ShopMainmost">

                <div className="shopDiv">
                    <div className="shopSub1">
                        <img src={productData.image} alt="" />
                    </div>

                    <div className="shopSub2">

                        <div className="reviewStarsShop">
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-regular fa-star"></i>
                            {/* <span>
                                {"21"} Reviews
                            </span> */}
                        </div>

                        <div className="shopSub2Sub1">
                            <h2>
                                {productData.productName}
                            </h2>

                            <span className="shopPrice">
                                <strike style={{ fontSize: "18px", padding: "2px 0px", color: "rgb(43, 43, 43)" }} ><i className="fa-solid fa-indian-rupee-sign"></i> {productData.actualPrice}</strike>
                                <span style={{ fontSize: "19px", color: "rgb(60, 60, 193)", fontWeight: "700", paddingLeft: "5px" }} > <i className="fa-solid fa-indian-rupee-sign"></i> {productData.discountPrice}</span>
                            </span>
                        </div>

                        <div className="quantityShop">
                            <p style={{ fontWeight: "700", fontSize: "17px" }}>QUANTITY</p>
                            <span>
                                <i className="fa fa-minus" onClick={reduceQuantity}></i>
                                <span>{quantity}</span>
                                <i className="fa fa-plus" onClick={addQuantity}></i>
                            </span>
                        </div>

                        <div className="shopSub2Sub2">
                            <p style={{ fontWeight: "700", fontSize: "17px" }}>DETAILS</p>
                            <p>
                                <span>Brand Name : </span>{productData.brandName}
                            </p>

                            <p>
                                <span>Product Category : </span> {productData.productCat}
                            </p>

                            <p>
                                <span>Availability : </span> {productData.inStock ? "In Stock" : "Out of stock"}
                            </p>
                        </div>


                        <div className="shopBtnsDv">
                            {
                                alreadyWishlist ? <button onClick={(e) => addToWishList(e, productData.productName, productData.productCat, productData.inStock, productData.actualPrice, productData.discountPrice, productData.image, productData.brandName, quantity, productData.email)} ><i className="fa fa-heart" style={{ color: "red" }}></i>
                                    Wishlist</button> : <button onClick={(e) => addToWishList(e, productData.productName, productData.productCat, productData.inStock, productData.actualPrice, productData.discountPrice, productData.image, productData.brandName, quantity, productData.email)} ><i className="fa fa-heart"></i> Wishlist</button>
                            }

                            <button onClick={(e) => addToCart(e, productData.productName, productData.productCat, productData.inStock, productData.actualPrice, productData.discountPrice, productData.image, productData.brandName, quantity, productData.email,)} ><i className="fa-solid fa-cart-shopping"></i> Add to cart</button>
                        </div>

                    </div>
                </div>

            </div>
            {/* product details ends*/}

            <div className="attributeShopMainMost">

                <div className="attributeShopDiv">
                    <div className="attributeSub">
                        <i className="fa-solid fa-truck"></i>
                        <span>Speedy Delivery</span>
                    </div>
                    <div className="attributeSub">
                        <i className="fa-solid fa-tools"></i>
                        <span>Assured Quality</span>
                    </div>
                    <div className="attributeSub">
                        <i className="fa-solid fa-magnifying-glass"></i>
                        <span>Trusted Brands</span>
                    </div>
                    <div className="attributeSub">
                        <i className="fa-solid fa-thumbs-up"></i>
                        <span>Genuine Products</span>
                    </div>
                </div>

            </div>

            <div style={{ width: "100%", height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ width: "100%", maxWidth: "1222px", height: "100px", borderTop: "1px solid black", }}></div>
            </div>

            {/* Section 3 */}

            {/* <div style={{ width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>

                <div className="testimonialsDiv">

                    <h2 className="reviewHeading">Guest Reviews</h2>

                    <div className="reviewsDiv">

                        <div className="reviewSubShop" style={{ width: "20%", background: "transparent", boxShadow: "none" }}>
                            <span className="reviewName reviewNameShop">4.9</span>
                            <div className="reviewStarsShop">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>

                            <div className="reviewPerson">
                                <div className="personDetails">
                                    <span className="reviewName">112 Star Ratings <br /> & Reviews</span>
                                </div>
                            </div>

                        </div>
                        <div className="reviewSub" style={{ width: "20%" }}>
                            <div className="reviewStars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>

                            <p className="reviewTxt">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit id deserunt nostrum, perspiciatis ratione corporis excepturi itaque placeat iure rerum?</p>

                            <div className="reviewPerson">
                                <div className="personDetails">
                                    <span className="reviewName">Name</span>
                                </div>
                            </div>

                        </div>
                        <div className="reviewSub" style={{ width: "20%" }}>
                            <div className="reviewStars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>

                            <p className="reviewTxt">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit id deserunt nostrum, perspiciatis ratione corporis excepturi itaque placeat iure rerum?</p>

                            <div className="reviewPerson">
                                <div className="personDetails">
                                    <span className="reviewName">Name</span>
                                </div>
                            </div>

                        </div>
                        <div className="reviewSub" style={{ width: "20%" }}>
                            <div className="reviewStars">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>

                            <p className="reviewTxt">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit id deserunt nostrum, perspiciatis ratione corporis excepturi itaque placeat iure rerum?</p>

                            <div className="reviewPerson">
                                <div className="personDetails">
                                    <span className="reviewName">Name</span>
                                </div>
                            </div>

                        </div>

                    </div>

                    <button className="moreRev">See More Reviews</button>

                </div>
            </div> */}

            {/* sec 3 ends */}

            {/* post review */}

            {/* <div style={{ width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>

                <div className="testimonialsDiv">

                    <h2 className="reviewHeading">Feel Free To Review !</h2>

                    <div className="shopReview">

                        <div className="shopReview1">
                            <textarea required autoComplete="off" name="reviewText" value={review.reviewText} onChange={setReviewOnChange} placeholder="Enter your Review here"></textarea>
                            <div className="shopReviewStars">
                                <i className="fa-regular fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                            </div>
                        </div>

                        <div className="shopReview2">
                            <input required autoComplete="off" name="reviewerName" value={review.reviewerName} onChange={setReviewOnChange} type="text" placeholder="Full Name" />
                            <input required autoComplete="off" name="reviewerEmail" value={review.reviewerEmail} onChange={setReviewOnChange} type="text" placeholder="Email" />
                            <input required autoComplete="off" name="reviewerPhone" value={review.reviewerPhone} onChange={setReviewOnChange} type="text" placeholder="Phone No." />
                        </div>
                    </div>

                    <button className="moreRev" style={{ marginTop: "15px" }} onClick={postingReview}>SUBMIT REVIEW</button>

                </div>
            </div> */}



        </div>

        <Footer />

    </>)
}

export default Shop;