import { useNavigate, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./style/Main.css";
import "./style/Common.css";
import "./style/Products.css";
import Logo from "../components/images/logonobg.png";

const NewProduct = () => {

    const navigate = useNavigate();

    const [productData, setProductData] = useState({
        productName: "", actualPrice: "", discountPrice: "", image: "", brandName: "",
    });

    const tokenn = Cookies.get("Shanti Dental Distributors");
    const [error, setError] = useState("");


    const [showProfiles, setShowProfiles] = useState(false);
    const [expandCat, setexpandCats] = useState("hidden");
    const settingCatExpand = () => {
        if (expandCat === "hidden") {
            setexpandCats("visible")
        } else {
            setexpandCats("hidden")
        }
    }

    const setLoginVal = (e) => {
        const { name, value } = e.target;
        setProductData({ ...productData, [name]: value });
    }

    const [stockAvail, setSTockAvail] = useState(true)
    const [visibility, setvisibility] = useState(true)
    const [productCat, setCat] = useState("Category");

    const addProduct = async (e) => {

        e.preventDefault();

        const { productName, actualPrice, discountPrice, image, brandName } = productData;
        console.log(visibility, stockAvail)

        if (productCat === "Category") {
            setError("Please Select a category")
        } else {
            try {
                const res = await fetch(`https://shantidentalbackend.vercel.app/usersasqwzxerdfcv/post/product`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        productName, productCat, visibility, inStock: stockAvail, actualPrice, discountPrice, image, brandName, tokenn
                    })
                });

                const data = await res.json();

                if (res.status === 200) {
                    alert("Product Added Success!")
                    setError("")
                } else {
                    if (data.error === "User not found") {
                        setError("Authentication Failed... Kindly login again")
                    } else if (data.error === "product already registered") {
                        setError("Product already added")
                    } else if (data.error === "Enter all the details") {
                        setError("Please fill all the details!")
                    }
                }

            } catch (error) {
                console.log(error);
            }
        }


    }

    // useEffect(() => {
    //     gettingDataHome();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])



    return (<>
        <div className="dashMain">

            {/* slide */}
            <div className="dashMainSlide">

                {/* img */}
                <NavLink to="/dashboard/home" style={{ color: "white", textDecoration: "none" }} className="mainImg">
                    <img src={Logo} alt="logo" />
                    <span>Shanti Dental <br /> Distributors</span>
                </NavLink>

                <div className="mainServices">
                    <NavLink className="mainServicesspan" to="/dashboard/profile" ><i className="fa-solid fa-user"></i><span>Profile</span></NavLink>
                    <div style={{ display: "flex", flexDirection: "column" }} onMouseEnter={() => setShowProfiles(true)} onMouseLeave={() => setShowProfiles(false)}>
                        <span className="mainServicesspan selectedService"  ><i className="fa-solid fa-box-archive"></i><span>Products</span></span>
                        {
                            showProfiles ? <div style={{ display: "flex", flexDirection: "column" }}  >
                                <NavLink className="mainServicesspan" to="/dashboard/allproducts">All Products</NavLink>
                                <NavLink className="mainServicesspan" to="/dashboard/newproducts">New Products</NavLink>
                                <NavLink className="mainServicesspan" to="/dashboard/productscat">Categories</NavLink>
                            </div> : ""
                        }
                    </div>
                    <NavLink className="mainServicesspan" to="/dashboard/orders"><i className="fa-solid fa-cart-shopping"></i><span>Orders</span></NavLink>
                    <NavLink className="mainServicesspan" to="/dashboard/customers"><i className="fa-solid fa-people-group"></i><span>Customers</span></NavLink>
                    <NavLink style={{ marginLeft: "3px" }} className="mainServicesspan" to="/dashboard/forms"><i className="fa-solid fa-book"></i><span style={{ marginLeft: "3px" }}  >Forms</span></NavLink>
                </div>

            </div>

            {/* Part next to start */}

            <div className="dashMainCntxt" style={{ background: "#ececec" }}>

                {/* // productName, productCat, visibility, taxStatus, taxClass, inStock, actualPrice, discountPrice, image, brandName, tokenn */}
                <h2 style={{ borderLeft: "7px solid #2271b1", paddingLeft: "20px", position: "relative", left: "35px" }} >ADD NEW PRODUCT</h2>

                <div className="dashProductsMain" style={{ alignItems: "flex-start", margin: "70px auto" }}>
                    <div className="dashProducts">
                        <div className="dashProductsInput">

                            <div className="dashInput1">
                                <input onChange={setLoginVal} type="text" placeholder="Product Name" name="productName" value={productData.productName} />

                                <div onClick={settingCatExpand} className="productCatDiv" placeholder="Product Category" >
                                    <span className="catBtn" >{productCat} <i className="fa-solid fa-chevron-down"></i></span>
                                    <div style={{ visibility: expandCat }} >
                                        <span onClick={() => setCat("PROSTHODONTICS")} >PROSTHODONTICS</span>
                                        <span onClick={() => setCat("ORTHODONTICS")} >ORTHODONTICS</span>
                                        <span onClick={() => setCat("ENDODONTICS")} >ENDODONTICS</span>
                                        <span onClick={() => setCat("MINOR EQUIPMENTS")} >MINOR EQUIPMENTS</span>
                                        <span onClick={() => setCat("PERIODONTICS")} >PERIODONTICS</span>
                                        <span onClick={() => setCat("GDC INSTRUMENTS")} >GDC INSTRUMENTS</span>
                                        <span onClick={() => setCat("RESTORATIVE DENTISTRY")} >RESTORATIVE DENTISTRY</span>
                                        <span onClick={() => setCat("PEDODONTICS")} >PEDODONTICS</span>
                                        <span onClick={() => setCat("DISINFECTION & STERILISATION")} >DISINFECTION & STERILISATION</span>
                                        <span onClick={() => setCat("ORAL SURGERY")} >ORAL SURGERY</span>
                                        <span onClick={() => setCat("RADIOGRAPHY")} >RADIOGRAPHY</span>
                                    </div>
                                </div>

                            </div>

                            <div className="dashInput1">
                                <input onChange={setLoginVal} type="text" placeholder="Brand Name" name="brandName" value={productData.brandName} />
                                <input onChange={setLoginVal} type="text" placeholder="Image URL" name="image" value={productData.image} />
                            </div>

                            <div className="dashInput1">
                                <input onChange={setLoginVal} type="number" placeholder="Actual Price" name="actualPrice" value={productData.actualPrice} />
                                <input onChange={setLoginVal} type="number" placeholder="Discount Price" name="discountPrice" value={productData.discountPrice} />
                            </div>

                            <div className="dashInput1">

                                <div className="stockDiv" placeholder="Product Category" name="productCat" value={""} >
                                    <span className={visibility ? "stockDivSelected" : ""} onClick={() => setvisibility(true)} >Visible</span>
                                    <span className={visibility ? "" : "stockDivSelected"} onClick={() => setvisibility(false)} >Hide</span>
                                </div>

                                <div className="stockDiv" style={{position:"relative", left:"165px"}}>
                                    <span className={stockAvail ? "stockDivSelected" : ""} onClick={() => setSTockAvail(true)} >In Stock</span><span className={stockAvail ? "" : "stockDivSelected"} onClick={() => setSTockAvail(false)} >Out of Stock</span>
                                </div>

                            </div>

                        </div>

                        <div className="dashProductsSubmit">
                            <p style={{ color: "red", fontSize: "18px", padding: "5px", fontFamily: "'Montserrat', sans-serif" }} >{error}</p>
                            <button onClick={addProduct} >PUBLISH</button>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </>)
}

export default NewProduct;