import "./style/Login.css";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import Logo from "../components/images/logo.png";

const Login = () => {

    const [loginData, setLoginData] = useState({
        email: "",
        password: ""
    });

    const navigate = useNavigate();
    const [error, setError] = useState("");

    const setLoginVal = (e) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    }

    const postLoginDetails = async (e) => {

        e.preventDefault();

        const { email, password } = loginData;

        try {
            const res = await fetch("https://shantidentalbackend.vercel.app/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email, password
                })
            });

            const data = await res.json();

            if (res.status === 200) {
                console.log(data.message);
                Cookies.set('Shanti Dental Distributors', data.message, {
                    expires: 10000000000
                });

                navigate(`/dashboard/home`);
            } else {
                console.log("Login Failed");
                if (data.error === "Invalid credentials") {
                    setError("Invalid credentials, please recheck the Email & Password")
                } else if (data.error === "Enter all the details") {
                    setError("Fill all the entries")
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    return (<>

        <div className="loginMainDashboard">

            <div className="loginDivDashBoard">

                <div className="loginSub1">

                    <div className="loginNavDash">
                        <NavLink style={{ textDecoration: "none" }} to="/"><img src={Logo} alt="logo" className="logoLogin" /></NavLink>
                        <NavLink style={{ textDecoration: "none" }} to="/"><span>Shanti Dental Distributors</span></NavLink>
                    </div>

                    <form className="loginInputsDashb">
                        <h2>DASHBOARD LOGIN</h2>
                        <div>
                            <input required autoComplete="off" type="text" name="email" placeholder="Email or Phone" value={loginData.email} onChange={setLoginVal} />
                            <input required autoComplete="off" type="password" name="password" placeholder="Password" value={loginData.password} onChange={setLoginVal} />
                        </div>
                        <p style={{ color: "red" }} >{error}</p>
                        <button className="loginBtnDash" type="submit" onClick={postLoginDetails}>Login</button>
                    </form>

                    <NavLink className="homeBtnDash" to="/"><i className="fa fa-house"></i></NavLink>

                </div>

            </div>

        </div>

    </>)

}

export default Login;