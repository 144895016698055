import { NavLink } from "react-router-dom";
import Logo from "../images/logo.png";
import SearchBox from "./Searchbox";
import Cart from "./Cart";
import MenuBar from "./Menu";
import Menu2 from "./Menu2";
import Menu3 from "./Menu3";

const NavBar = () => {
    return (
        <>
            {/* Navbar */}
            <div className="navbarMain">

                {/* desktop */}
                <div className="navBar" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>

                    <NavLink style={{ textDecoration: "none" }} to="/">
                        <img src={Logo} alt="logo" className="logoNav" /> <span className="logoMaintext">Shanti Dental Distributors</span>
                    </NavLink>

                    {/* <i className="fa-solid fa-bars menuNav"></i> */}

                    {/* <MenuBar /> */}
                    <SearchBox />


                    <div className="navAccBox">
                        <NavLink to="/login">
                            <i style={{ color: "black" }} className="fa-regular fa-user userIcon"></i>{/*<span className="superTxt"></span> */}
                        </NavLink>

                        <NavLink to="/wishlist" style={{textDecoration:"none", color:"black"}}>
                            <i className="fa-regular fa-heart"></i><span className="superTxt">0</span>
                        </NavLink>

                        {/* <i className="fa-solid fa-cart-shopping"></i><span className="superTxt">0</span> */}
                        <Cart /><span className="superTxt">0</span>
                    </div>

                </div>

                {/* mobile */}
                <div className="navBarMobile" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>

                    <NavLink style={{ textDecoration: "none" }} to="/">
                        <img src={Logo} alt="logo" className="logoNav logoNavMob" /> <span className="logoMaintextMobile">Shanti Dental Distributors</span>
                    </NavLink>

                    {/* <MenuBar /> */}
                    <Menu3 />

                </div>

            </div>
        </>
    )
}

export default NavBar;