import { NavLink } from "react-router-dom";
import { useEffect } from "react";

import Logo from "../images/logo.png";
import Cart from "./Cart";
import Footer from "./Footer";
import "../../style/About.css";
import SearchBox from "./Searchbox";
import NavBar from "./Navbar";

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <div className="aboutMain" style={{minHeight:"180px"}}>
                {/* top line */}
                <div className="topBarMain">

                    <div className="topBar">
                        <div className="topBarOne">
                            <p style={{ fontFamily: "'Jost', sans-serif" }} >FREE SHIPPING FOR ALL ORDERS ABOVE ₹ 1,999</p>
                        </div>
                        <div className="topBarTwo">
                            <i className="fa-brands fa-facebook-f"></i>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://www.instagram.com/shantidentalhyd?igsh=MWdlYXVpdHIzMGE4Yg==">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://g.co/kgs/UdorgXw">
                                <i className="fa-brands fa-google"></i>
                            </a>
                            <a style={{ textDecoration: "none", color: "black" }} href="https://wa.me/919704726590">
                                <i className="fa-brands fa-whatsapp"></i>
                            </a>
                            {/* <i className="fa-brands fa-telegram"></i> */}
                        </div>
                    </div>

                </div>

                {/* Navbar */}
                <NavBar />

            </div>

            {/* About content */}

            <div className="aboutCntxtMain">
                <div className="aboutCntxt">
                    <div className="abtImgDiv">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="abtContentDiv">
                        <h2 style={{ borderLeft: "7px solid #2271b1", paddingLeft: "20px", position: "relative", left: "10px", margin: "20px auto" }} >ABOUT US</h2>
                        <p>Welcome to Shanti Dental, a pioneering venture founded in 1995 in Hyderabad by a team of seasoned dental professionals. Our journey began with a simple yet profound commitment: to craft products that seamlessly integrate with the art and science of implant dentistry, ensuring enduring solutions for practitioners and patients alike.</p>
                        <p>At Shanti Dental, we blend innovation with expertise, striving to deliver a comprehensive array of cutting-edge products that empower dentists to elevate their practice standards. With a relentless pursuit of excellence, we continually push the boundaries of dental technology to provide practitioners with the tools they need to stay at the forefront of modern dentistry.</p>
                    </div>
                </div>
                <div className="abtContentDiv" style={{ width: "100%", maxWidth: "1222px" }}>
                    <p style={{ padding: "2px" }}>Our ethos revolves around the core belief that every dental procedure should not only meet but exceed the expectations of patients. Hence, we meticulously design and develop our products with a focus on long-term compatibility, durability, and efficacy, ensuring optimal outcomes and patient satisfaction.</p>
                    <p style={{ padding: "2px" }}>Driven by a passion for innovation and a dedication to quality, we are committed to serving the dental community both nationally and internationally. Our goal is not just to meet industry standards but to redefine them, setting new benchmarks in dental care and advancing the field of implant dentistry.</p>
                    <p style={{ padding: "2px" }}>As we embark on this journey of excellence, we invite you to join us in shaping the future of dentistry, one smile at a time. Explore our range of technologically advanced products and experience the Shanti Dental difference today. Together, let’s create a world where dental excellence knows no bounds.</p>
                </div>
            </div>

            {/* footer */}
            <Footer />

        </>
    )
}

export default About;