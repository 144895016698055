import Syringe from "../images/products/Aspirating-Syringe.jpg"

const featuredProducts = [
    {
        _id: "6650fc3b95bc3138c048fc07",
        productName: "ACE KIT",
        productCat: "Prosthodontics",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 3500,
        discountPrice: 2800,
        discountPercentage: 20,
        image: "https://eshantidental.com/wp-content/uploads/2021/06/ACE-Kit.jpg",
        brandName: "Prevest DenPro"
    },
    {

        _id: "6650fd1b6f483e3b73d726c4",
        productName: "24 Carat ECONOMY PACK 22%",
        productCat: "Restorative Dentistry",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1350,
        discountPrice: 1080,
        image: "https://eshantidental.com/wp-content/uploads/2021/06/24-CARAT-ECONOMY-PACK.jpg",
        brandName: "Prevest DenPro"

    },
    {

        _id: "6650fd796f483e3b73d726ca",
        productName: "Actino GEL INTRO PACK",
        productCat: "Restorative Dentistry",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 265,
        discountPrice: 212,
        image: "https://eshantidental.com/wp-content/uploads/2021/06/ACTINO-GEL-INTRO-PACK.jpg",
        brandName: "Prevest DenPro"

    },
    {

        _id: "6650fd4e6f483e3b73d726c7",
        productName: "ACTINO GEL ECONOMY PACK",
        productCat: "Restorative Dentistry",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1000,
        discountPrice: 800,
        image: "https://eshantidental.com/wp-content/uploads/2021/06/Actino-Gel-Kit-scaled.jpg",
        brandName: "Prevest DenPro"

    },
    {
        _id: "665124c109c864e8a41bfa06",
        productName: "GDC ASSORTED LUXATIPS",
        productCat: "GDC Instruments",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 18900,
        discountPrice: 11200,
        image: "https://eshantidental.com/wp-content/uploads/2021/05/Luxatip-L5C.png",
        brandName: "GDC"
    },
    {
        _id: "6651a17ec11b7b3f8b5d0756",
        productName: "GDC AUSTIN CHEEK RETRACTOR",
        productCat: "GDC Instruments",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1260,
        discountPrice: 840,
        image: "https://eshantidental.com/wp-content/uploads/2021/05/Austin-Cheek-Retractor-Halfmoon-TRA3-1.jpg",
        brandName: "GDC",
    },
    {
        _id: "6651a219c11b7b3f8b5d0767",
        productName: "GDC ASPIRATING SYRINGE",
        productCat: "GDC Instruments",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1350,
        discountPrice: 896,
        image: "https://eshantidental.com/wp-content/uploads/2021/05/Aspirating-Syringe-SYRA1.8ml-1.jpg",
        brandName: "GDC"
    },
    {
        _id: "665435626172594239a6f698",
        productName: "GDC BONE CURETTE",
        productCat: "GDC Instruments",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 450,
        discountPrice: 280,
        image: "https://eshantidental.com/wp-content/uploads/2021/05/Bone-Curette-CH877-3-1.jpg",
        brandName: "GDC"
    },
    {
        _id: "665435936172594239a6f69b",
        productName: "GDC BONE FIME MILLER",
        productCat: "GDC Instruments",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 570,
        discountPrice: 392,
        image: "https://eshantidental.com/wp-content/uploads/2021/05/Bone-File-Miller-BF52-1.png",
        brandName: "GDC",
    },
    {
        _id: "665435b76172594239a6f69e",
        productName: "GDC BRAKET HOLDING TWEEZERS",
        productCat: "GDC Instruments",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 1050,
        discountPrice: 650,
        image: "https://eshantidental.com/wp-content/uploads/2021/05/Bracket-Holding-Tweezer-1006.jpg",
        brandName: "GDC"
    },
    {
        _id: "665436136172594239a6f6a4",
        productName: "GDC COMPOSITE INSTRUMENT BLUE SET-6",
        productCat: "GDC Instruments",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 10170,
        discountPrice: 6160,
        image: "https://eshantidental.com/wp-content/uploads/2021/05/Composite-instrument-Titanium-Blue-CIB6-1.png",
        brandName: "GDC"
    },
    {
        _id: "665435626172594239a6f698",
        productName: "GDC BONE CURETTE",
        productCat: "GDC Instruments",
        taxStatus: "taxable",
        taxClass: "GST",
        inStock: true,
        actualPrice: 450,
        discountPrice: 280,
        image: "https://eshantidental.com/wp-content/uploads/2021/05/Bone-Curette-CH877-3-1.jpg",
        brandName: "GDC"
    },


];


export default featuredProducts;