import orderPlaced from "../images/Orderplaced.mp4";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const OrderPlaced = () => {

    const history = useNavigate();

    useEffect(() => {
        setTimeout(()=>{
            history("/")
           }, 12000)
    }, [history]);

    return (<>
        <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }} className="orderPlacedMain">
            <div style={{ width: "100%", height: "100vh", maxWidth: "1222px", display: "flex", alignItems: "center", justifyContent: "center" }} >
                <video style={{ width: "100%", height: "100vh", maxWidth: "1222px", display: "flex", alignItems: "center", justifyContent: "center" }} autoPlay muted>
                    <source src={orderPlaced} />
                </video>
            </div>
        </div>  </>)
}

export default OrderPlaced;